import { Container, Grid, Typography } from "@mui/material";
import React from "react";

export const AboutUs = () => {
  return (
    <Container
      style={{
        backgroundColor: "#fff",
        padding: "10px 0",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <img
        src={
          "https://ampious.in/wp-content/uploads/2024/08/cropped-Ampious-transparent-logo-150x150.png"
        }
      />
      <Typography variant="h3">About Us</Typography>
      <Grid container>
        <Grid item mt={3} padding={"0 5px"}>
          <div className="entry-content wp-block-post-content is-layout-flow wp-block-post-content-is-layout-flow">
            <h2 className="wp-block-heading">Welcome</h2>
            <p>
              Welcome to Ampious, where innovation meets dedication.Our mission
              is to provide more power to small and medium sized gyms, driving
              us to continually push boundaries and set new standards. Our
              passionate team of experts brings together diverse skills and
              backgrounds, united by a shared vision of excellence and customer
              satisfaction. Join us on our journey as we strive to make a
              difference, one service at a time.
            </p>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
