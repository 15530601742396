import axios from "axios";
import { API_HOSTNAME } from "../shared/constants";

export class ServiceWrapper {
  callGet = (url: string) => {
    return axios.get(url);
  };
  callPost = (url: string, body: any): Promise<any> => {
    return axios.post(url, body);
  };
  callPut = (url: string, body: any) => {
    return axios.put(url, {
      data: body,
    });
  };
  callDelete = (url: string, body: any) => {
    return axios.delete(url, {
      data: body,
    });
  };
  callGetWithParams = (url: string, params: any) => {
    return axios.get(url, {
      params: params,
    });
  };
  uploadMedia = (base64Image: string) => {
    return axios.post(`${API_HOSTNAME}/assets/upload`, {
      file: base64Image,
    });
  };
}
