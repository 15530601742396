import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
} from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { IClasses } from "../../../interfaces/IClasses";
import axios from "axios";
import { API_HOSTNAME } from "../../../shared/constants";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import Swal from "sweetalert2";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Link } from "react-router-dom";

export const AddClasses = () => {
  const title = "Add Classes";
  const callSerivce = new ServiceWrapper();
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [classes, setClasses] = useState<IClasses[]>([]);
  const [staffName, setStaffName] = useState<[{}]>([{}]);
  const [currentTab, setCurrentTab] = useState<number>(0);

  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;
  const [classType, setClassType] = useState([]);
  const [classData, setClassData] = useState<IClasses>({
    gym_id: "",
    class_type: "",
    day: "",
    class_start_timing: "",
    class_end_timing: "",
    added_by: "",
    updated_by: "",
    staff_user_id: "",
  });

  useEffect(() => {
    document.title = `Ampious : ${title}`;
  }, []);

  useEffect(() => {
    if (gym) {
      getClassType();
      setClassData({
        ...classData,
        gym_id: gym!._id!,
        updated_by: gym!.gym_owner_id,
        added_by: gym!.gym_owner_id,
      });
    }
  }, [gym]);

  useLayoutEffect(() => {
    if (gym) {
      getClassType();
      setClassData({
        ...classData,
        gym_id: gym!._id!,
        updated_by: gym!.gym_owner_id,
        added_by: gym!.gym_owner_id,
      });
    }
  }, [gym]);

  const getClassType = async () => {
    const response = await callSerivce.callGet(
      `${API_HOSTNAME}/master/gym-class-types`,
    );
    setClassType(response.data);
  };

  const timeOptions: string[] = Array.from({ length: 24 }, (_, index) => {
    const hours = String(index).padStart(2, "0");
    const minutes = "00";
    return `${hours}:${minutes}`;
  });

  const handleSubmit = async () => {
    try {
      const response = await callSerivce.callPost(
        `${API_HOSTNAME}/gym_info/add-gym-classes`,
        classData,
      );
      console.log(response);
      Swal.fire("Class Added Successfully", "", "success");
      setClassData({
        ...classData,
        class_type: "",
        day: "",
        class_start_timing: "",
        class_end_timing: "",
        staff_user_id: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getClasses = async () => {
    const response = await callSerivce.callGet(
      `${API_HOSTNAME}/gym_info/get-gym-classes/${classData.gym_id}`,
    );
    setClasses(response.data.data);
  };

  useEffect(() => {
    if (classData.gym_id) {
      getClasses();
    }
  }, [classData.gym_id]);

  useEffect(() => {
    if (gym && gym!._id) {
      getGymStaffId();
    }
  }, [gym]);

  const getGymStaffId = async () => {
    const response = await axios.get(
      `${API_HOSTNAME}/gym-staff/get-my-staff/${gym!._id}`,
    );
    setStaffName(response.data.data);
  };
  const classColumns: GridColDef[] = [
    {
      field: "class_type",
      headerName: "Class Type",
      width: 200,
    },
    {
      field: "day",
      headerName: "Day",
      width: 200,
    },
    {
      field: "class_start_timing",
      headerName: "Class Time",
      width: 200,
    },
    {
      field: "class_end_timing",
      headerName: "End Time",
      width: 200,
    },
    {
      field: `mentor`,
      headerName: "Mentor",
      width: 200,
      renderCell: (params) => {
        return params.row.mentor.first_name;
      },
    },
  ];

  const toggletabs = (event: React.SyntheticEvent, value: any) => {
    console.log("VAlue", value);
    if (value === "1") {
    } else {
      // getMyPlans()
    }
    setCurrentTab(value);
  };

  return (
    <div className="h-screen" style={{ backgroundColor: "#fff" }}>
      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <div className="card-header d-flex justify-content-between border-bottom pb-1">
          <Grid
            container
            padding={"0.5rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>{title}</Grid>
          </Grid>
        </div>
      </div>
      <TabContext value={currentTab.toString()}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={toggletabs} aria-label="simple tabs example">
            <Tab label="View Classes" value="0" />
            <Tab label="Add Class" value="1" />
          </TabList>
        </Box>
        <TabPanel value="0" style={{ backgroundColor: "#fff" }}>
          <h2 className="mt-3 mb-3">Current Classes</h2>
          <DataGrid
            rows={classes}
            columns={classColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  page: 0,
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            getRowId={(row) => row._id}
          />
        </TabPanel>
        <TabPanel value="1" style={{ backgroundColor: "#fff" }}>
          <h2 className="mt-3">Add Class</h2>
          <div className="card-body">
            <FormControl
              fullWidth
              sx={{
                marginTop: "1rem",
              }}
            >
              <InputLabel>Class Type</InputLabel>
              <Select
                value={classData.class_type}
                label="Class Type"
                onChange={(e) =>
                  setClassData({ ...classData, class_type: e.target.value })
                }
              >
                {Object.entries(classType).map(([key, value]) => (
                  <MenuItem value={key}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              sx={{
                marginTop: "1rem",
              }}
            >
              <InputLabel>Days</InputLabel>
              <Select
                value={classData.day}
                label="Day"
                onChange={(e) =>
                  setClassData({ ...classData, day: e.target.value })
                }
              >
                {days.map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              sx={{
                marginTop: "1rem",
              }}
            >
              <InputLabel>Start Time</InputLabel>
              <Select
                value={classData.class_start_timing}
                label="Day"
                onChange={(e) =>
                  setClassData({
                    ...classData,
                    class_start_timing: e.target.value,
                  })
                }
              >
                {timeOptions.map((time) => (
                  <MenuItem value={time}>{time}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              sx={{
                marginTop: "1rem",
              }}
            >
              <InputLabel>End Time</InputLabel>
              <Select
                value={classData.class_end_timing}
                label="Day"
                onChange={(e) =>
                  setClassData({
                    ...classData,
                    class_end_timing: e.target.value,
                  })
                }
              >
                {timeOptions.map((time) => (
                  <MenuItem value={time}>{time}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: "1rem" }}>
              <InputLabel>Select Staff</InputLabel>
              <Select
                value={classData.staff_user_id}
                label="Staff Id "
                onChange={(event) => {
                  setClassData({
                    ...classData,
                    staff_user_id: event.target.value,
                  });
                }}
              >
                {staffName.map((item: any) => (
                  <MenuItem value={item._id}>
                    {item.first_name + " " + item.last_name}
                  </MenuItem>
                ))}
              </Select>
              <Link to="/staff" style={{ alignSelf: "flex-end" }}>
                Add Staff
              </Link>
            </FormControl>
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              className="btn btn-primary btn-sm mt-3"
              sx={{ marginTop: "1rem" }}
            >
              Add
            </Button>
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
};
