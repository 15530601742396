import * as React from "react";
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import { Editor } from "@tinymce/tinymce-react";
import { CustomButton } from "../../../shared";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import {
  API_HOSTNAME,
  ROOT_HOSTNAME,
  THEME_ORANGE,
  TinyMCE,
} from "../../../shared/constants";
import { useParams } from "react-router-dom";
import { IGymCustomerAssignment } from "../../../interfaces";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CustomWorkoutPlan = () => {
  const params = useParams();
  const title = "Custom Workout Manager";
  const service = new ServiceWrapper();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;
  const [openCustomerModal, setShouldOpenCustomerModal] =
    React.useState<boolean>(false);
  const [searchCustomerQuery, setSearchQuery] = React.useState<string>("");
  const [customerList, setCustomerList] = React.useState<
    IGymCustomerAssignment[]
  >([]);
  const [assignedCustomersInThisPlan, setAssignedCustomersInThisPlan] =
    React.useState<IGymCustomerAssignment[]>([]);

  const [workoutText, setWorkoutText] = React.useState({
    monday: "",
    tuesday: "",
    wednesday: "",
    thrusday: "",
    friday: "",
    saturday: "",
    sunday: "",
  });

  React.useEffect(() => {
    document.title = `Ampious : ${title}`;
    user && getCustomPlanDetails();
  }, [user]);

  const getCustomPlanDetails = () => {
    service
      .callGet(`${API_HOSTNAME}/workouts/get-custom-workouts/${params.plan_id}`)
      .then((response) => {
        const obj = {
          monday: "",
          tuesday: "",
          wednesday: "",
          thrusday: "",
          friday: "",
          saturday: "",
          sunday: "",
        };
        obj.monday = response.data.data.find(
          (r: any) => r.day === "monday",
        )?.exercises;
        obj.tuesday = response.data.data.find(
          (r: any) => r.day === "tuesday",
        )?.exercises;
        obj.wednesday = response.data.data.find(
          (r: any) => r.day === "wednesday",
        )?.exercises;
        obj.thrusday = response.data.data.find(
          (r: any) => r.day === "thrusday",
        )?.exercises;
        obj.friday = response.data.data.find(
          (r: any) => r.day === "friday",
        )?.exercises;
        obj.saturday = response.data.data.find(
          (r: any) => r.day === "saturday",
        )?.exercises;
        obj.sunday = response.data.data.find(
          (r: any) => r.day === "sunday",
        )?.exercises;
        setWorkoutText(obj);
      });
  };
  const onChange = (content: any, day: string) => {
    setWorkoutText({
      ...workoutText,
      [day]: content,
    });
  };

  const saveCustomWorkout = () => {
    service
      .callPost(`${API_HOSTNAME}/workouts/add-custom-workouts`, {
        workoutData: workoutText,
        plan_id: params.plan_id,
      })
      .then((response) => {});
  };

  const openCustomerList = () => {
    setShouldOpenCustomerModal(true);
    setSearchQuery("");
    searchCustomers();
  };

  const searchCustomers = () => {
    service
      .callPost(
        `${API_HOSTNAME}/gym_customer/search-my-customers/${gym!._id}/${params.plan_id}`,
        {
          keyword: searchCustomerQuery,
        },
      )
      .then((response) => {
        let cleanedData = [];
        let filteredData = response.data.filteredCustomers;
        let assignedCustomerInThisPlan =
          response.data.assignedCustomerInThisPlan;
        setAssignedCustomersInThisPlan(assignedCustomerInThisPlan);
        if (filteredData.length) {
          cleanedData = response.data.filteredCustomers.map((c: any) => {
            if (
              assignedCustomerInThisPlan.filter(
                (d: any) => d.customer_id === c._id,
              ).length > 0
            ) {
              c.selected = true;
            } else {
              c.selected = false;
            }
            return c;
          });
        }
        console.log("cleanedData", cleanedData);
        setCustomerList(cleanedData);
      });
  };

  const isAlreadySelectedForAssignment = (customer: IGymCustomerAssignment) => {
    return customer.selected;
  };

  const assignPlanToCustomer = () => {
    const body = [];
    for (let i = 0; i < customerList.length; i++) {
      body.push({
        customer_id: customerList[i]._id,
        workout_plan_id: params.plan_id,
        gym_id: gym?._id,
        selected: customerList[i].selected,
      });
    }

    service
      .callPost(`${API_HOSTNAME}/workouts/assign_workout_to_customer`, body)
      .then((response) => {
        console.log("saved");
      });
  };

  const toggleCustomerAssignment = (customer: IGymCustomerAssignment) => {
    setCustomerList(
      customerList.map((c) => {
        if (customer._id === c._id) {
          if (c.selected) {
            c.selected = false;
          } else {
            c.selected = true;
          }
        }
        return c;
      }),
    );
  };

  const getAllAssignedCustomers = () => {
    console.log("assignedCustomerInThisPlan", assignedCustomersInThisPlan);
    setCustomerList(
      assignedCustomersInThisPlan.map((r) => {
        return {
          // @ts-ignore
          ...r.customer_info,
          selected: true,
        };
      }),
    );
  };

  const downloadWorkout = () => {
    service
      .callGet(`${API_HOSTNAME}/workouts/export_to_pdf/${params.plan_id}`)
      .then((response) => {
        window.open(`${ROOT_HOSTNAME}/${params.plan_id}.pdf`, "_blank");
      });
  };

  return (
    <div className="h-screen">
      <Dialog
        fullScreen
        open={openCustomerModal}
        onClose={() => {
          setShouldOpenCustomerModal(false);
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: THEME_ORANGE }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setShouldOpenCustomerModal(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Assign to customers
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setShouldOpenCustomerModal(false);
              }}
            >
              Cancel
            </Button>
          </Toolbar>
        </AppBar>

        <Container style={{ marginTop: 50 }}>
          <Grid container>
            <Grid item marginLeft={1.5}>
              <h3>Assign workout plan to Customers</h3>
            </Grid>
            <Grid container style={{ padding: 10 }} direction={"row"} gap={2}>
              <Grid item flex={0.8}>
                <TextField
                  inputProps={{
                    style: {
                      height: 8,
                    },
                  }}
                  fullWidth
                  placeholder="Search your customers"
                  value={searchCustomerQuery}
                  onKeyUp={(e) => {
                    e.key.toLowerCase() === "enter" && searchCustomers();
                  }}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={searchCustomers}
                  style={{ backgroundColor: THEME_ORANGE }}
                  variant="contained"
                >
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={getAllAssignedCustomers}
                  style={{ backgroundColor: THEME_ORANGE }}
                  variant="contained"
                >
                  Get all Assigned Customers
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={"column"}>
            <Grid item style={{ height: 500, overflowY: "scroll" }}>
              <List sx={{ width: "100%", maxWidth: "100%" }}>
                {customerList.map((value, index) => {
                  return (
                    <ListItem key={index} style={{ width: "100%" }}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={isAlreadySelectedForAssignment(value)}
                          onChange={(e) => {
                            console.log("herer", value);
                            toggleCustomerAssignment(value);
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={index.toString()}
                        style={{ textTransform: "capitalize" }}
                        primary={`${value.first_name} ${value.last_name}`}
                        secondary={`${value.email} ${value.phone_number || "NA"}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item mt={5}>
              <Button
                fullWidth
                onClick={assignPlanToCustomer}
                style={{ backgroundColor: THEME_ORANGE, height: 50 }}
                variant="contained"
              >
                Assign to selected customers
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Dialog>

      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <Grid
          container
          className="card-header d-flex border-bottom pb-1"
          flex={1}
        >
          <Grid item container flex={1} justifyContent={"space-between"} mb={2}>
            <Grid item alignContent={"center"}>
              <span>{title}</span>
            </Grid>
            <Grid item container justifyContent={"flex-end"} gap={2}>
              <CustomButton
                text="Save Workouts"
                fullWidth={false}
                type="success"
                onClick={() => {
                  saveCustomWorkout();
                }}
              />
              <Grid item>
                <CustomButton
                  text="Assign this Workout to customers"
                  fullWidth={false}
                  type="success"
                  onClick={() => {
                    openCustomerList();
                  }}
                />
              </Grid>
              <CustomButton
                text="Download"
                fullWidth={false}
                type="success"
                onClick={() => {
                  downloadWorkout();
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <div className="card-body">
          <Grid container direction="column">
            <Grid item container id={"monday"} direction="column" gap={2}>
              <Grid
                item
                container
                direction="row"
                justifyContent={"space-between"}
              >
                <Grid item>
                  <Typography component={"b"}>Monday</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Editor
                  onEditorChange={(props) => {
                    onChange(props, "monday");
                  }}
                  plugins={TinyMCE.plugins}
                  toolbar={TinyMCE.toolbar}
                  apiKey="xkebtlf415019033tvwalsr3ivu2ina19tdqltmumhylsws2"
                  value={workoutText["monday"]}
                  onPaste={(a) => {
                    onChange(a, "monday");
                  }}
                />
              </Grid>
            </Grid>

            <Grid item id={"tuesday"} paddingTop={2}>
              <Typography>Tuesday</Typography>
              <Grid item>
                <Editor
                  onEditorChange={(props) => {
                    onChange(props, "tuesday");
                  }}
                  plugins={TinyMCE.plugins}
                  toolbar={TinyMCE.toolbar}
                  apiKey="xkebtlf415019033tvwalsr3ivu2ina19tdqltmumhylsws2"
                  value={workoutText["tuesday"]}
                  onPaste={(a) => {
                    onChange(a, "tuesday");
                  }}
                />
                {/* <Grid item marginTop={3}>
									<CustomButton
										text="Save Workout for Tuesday"
										fullWidth
										type="success"
										onClick={() => {
											saveCustomWorkout("tuesday");
										}}
									/>
								</Grid> */}
              </Grid>
            </Grid>
            <Grid item id={"wednesday"} paddingTop={2}>
              <Typography>Wednesday</Typography>
              <Grid item>
                <Editor
                  onEditorChange={(props) => {
                    onChange(props, "wednesday");
                  }}
                  plugins={TinyMCE.plugins}
                  toolbar={TinyMCE.toolbar}
                  apiKey="xkebtlf415019033tvwalsr3ivu2ina19tdqltmumhylsws2"
                  value={workoutText["wednesday"]}
                  onPaste={(a) => {
                    onChange(a, "wednesday");
                  }}
                />
                {/* <Grid item marginTop={3}>
									<CustomButton
										text="Save Workout for Wednesday"
										fullWidth
										type="success"
										onClick={() => {
											saveCustomWorkout("wednesday");
										}}
									/>
								</Grid> */}
              </Grid>
            </Grid>
            <Grid item id={"thrusday"} paddingTop={2}>
              <Typography>Thrusday</Typography>
              <Grid item>
                <Editor
                  onEditorChange={(props) => {
                    onChange(props, "thrusday");
                  }}
                  plugins={TinyMCE.plugins}
                  toolbar={TinyMCE.toolbar}
                  apiKey="xkebtlf415019033tvwalsr3ivu2ina19tdqltmumhylsws2"
                  value={workoutText["thrusday"]}
                  onPaste={(a) => {
                    onChange(a, "thrusday");
                  }}
                />
                {/* <Grid item marginTop={3}>
									<CustomButton
										text="Save Workout for Thrusday"
										fullWidth
										type="success"
										onClick={() => {
											saveCustomWorkout("thrusday");
										}}
									/>
								</Grid> */}
              </Grid>
            </Grid>

            <Grid item id={"friday"} paddingTop={2}>
              <Typography>Friday</Typography>
              <Grid item>
                <Editor
                  onLoadContent={() => {
                    return <CircularProgress />;
                  }}
                  onEditorChange={(props) => {
                    onChange(props, "friday");
                  }}
                  plugins={TinyMCE.plugins}
                  toolbar={TinyMCE.toolbar}
                  apiKey="xkebtlf415019033tvwalsr3ivu2ina19tdqltmumhylsws2"
                  value={workoutText["friday"]}
                  onPaste={(a) => {
                    onChange(a, "friday");
                  }}
                />

                {/* <Grid item marginTop={3}>
									<CustomButton
										text="Save Workout for Friday"
										fullWidth
										type="success"
										onClick={() => {
											saveCustomWorkout("friday");
										}}
									/>
								</Grid> */}
              </Grid>
            </Grid>

            <Grid item id={"saturday"} paddingTop={2}>
              <Typography textTransform={"capitalize"}>Saturday</Typography>
              <Grid item>
                <Editor
                  onEditorChange={(props) => {
                    onChange(props, "saturday");
                  }}
                  plugins={TinyMCE.plugins}
                  toolbar={TinyMCE.toolbar}
                  apiKey="xkebtlf415019033tvwalsr3ivu2ina19tdqltmumhylsws2"
                  value={workoutText["saturday"]}
                  onPaste={(a) => {
                    onChange(a, "saturday");
                  }}
                />

                {/* <Grid item marginTop={3}>
									<CustomButton
										text="Save Workout for Saturday"
										fullWidth
										type="success"
										onClick={() => {
											saveCustomWorkout("saturday");
										}}
									/>
								</Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
