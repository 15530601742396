import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import { API_HOSTNAME } from "../../../shared/constants";
import React from "react";
import { Calendar, momentLocalizer, View } from "react-big-calendar";
import moment from "moment";
import {
  IStaffDetail,
  IStaffDetailResponse,
} from "../../../interfaces/IStaffDetail";
import { IGlobalStoreState } from "../../../store/store";
import { useSelector } from "react-redux";

export const StaffAttendance = () => {
  const title = "Ampious: Staff Attendance";
  const params = useParams();
  const service = new ServiceWrapper();
  const localizer = momentLocalizer(moment);
  const [staff, setStaff] = React.useState<IStaffDetailResponse[]>([]);
  const [selectedStaffId, setSelectedStaffId] = React.useState<string>("");
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;

  const [searchParams, setSearchParams] = useSearchParams();
  const [calenderEvents, setCalenderEvents] = React.useState<
    {
      start: any;
      end: any;
      title: string;
    }[]
  >([]);

  useEffect(() => {
    document.title = title;
    gym && getStaff();
  }, [gym]);

  React.useEffect(() => {
    if (searchParams.get("staff_id")) {
      setSelectedStaffId(searchParams.get("staff_id")!);
      // getAttendaceInfo();
    }
  }, [searchParams]);

  React.useEffect(() => {
    selectedStaffId && getAttendaceInfo();
  }, [selectedStaffId]);

  const getStaff = () => {
    service
      .callGet(`${API_HOSTNAME}/gym-staff/get-my-staff/${gym!._id}`)
      .then((response) => {
        console.log("Get Staff Response", response);
        setStaff(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getAttendaceInfo = () => {
    service
      .callGet(
        `${API_HOSTNAME}/gym-staff/get-staff-attendance/${selectedStaffId}`,
      )
      .then((response) => {
        console.log("response", response);
        let event = {
          start: new Date(),
          end: new Date(),
          title: "",
        };
        let events: any = [];
        for (let i = 0; i < response.data.length; i++) {
          let selectedObject = response.data[i];
          events.push({
            start: response.data[i].date_of_attendance,
            end: response.data[i].date_of_attendance,
            title: response.data[i].attendance_status,
            id: response.data[i]._id,
          });
        }
        console.log("events", events);
        setCalenderEvents(events);
      });
  };

  return (
    <div className="h-screen" style={{ backgroundColor: "#fff" }}>
      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <div className="card-header d-flex justify-content-between border-bottom pb-1">
          <Grid
            container
            padding={"0.5rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>{title}</Grid>
          </Grid>
        </div>

        <Grid style={{ backgroundColor: "#fff", padding: 10 }}>
          <Grid item gap={2}>
            <h5>View attendance of Staff</h5>
            <FormControl fullWidth style={{ margin: "10px 0" }}>
              <InputLabel>Select Staff</InputLabel>
              <Select
                placeholder="Select Staff"
                defaultChecked={true}
                defaultValue={
                  searchParams.get("staff_id")
                    ? searchParams.get("staff_id")
                    : ""
                }
                onChange={(e) => {
                  setSelectedStaffId(e.target.value?.toString()!);
                }}
              >
                {staff.map((staff) => {
                  return (
                    <MenuItem
                      value={staff._id}
                      style={{ textTransform: "capitalize" }}
                    >
                      {staff.first_name} {staff.last_name} ({staff.email})
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Calendar
              showMultiDayTimes
              defaultView="month"
              doShowMoreDrillDown={false}
              onView={(view: View) => {
                console.log("view", view);
              }}
              view="month"
              localizer={localizer}
              events={calenderEvents}
              selectable
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              onSelectSlot={(e) => {}}
              eventPropGetter={(event) => {
                const backgroundColor =
                  event.title === "present" ? "green" : "red";
                return { style: { backgroundColor } };
              }}
              onSelectEvent={(e) => {
                console.log(e);
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
