import { styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";

interface INumberInput {
  onChange: (text: string) => void;
  value: string;
  placeholder: string;
}

export const NumberInput = (props: INumberInput) => {
  return (
    <Input
      type="number"
      error={true}
      placeholder={props.placeholder}
      value={props.value}
      required
      onChange={(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        props.onChange(e.target.value);
      }}
    />
  );
};

const Input = styled(InputBase)`
  height: 55px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding-left: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;
