import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { API_HOSTNAME } from "../../shared/constants";
import {
  setGymInfoAction,
  setGymStaffAction,
  setUserAction,
  setUserTokenAction,
  setUserTypeAction,
} from "../../store";
import Swal from "sweetalert2";
import {
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../../assets/photo/image-removebg-preview.png";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../store/store";
import { ServiceWrapper } from "../../services/serviceWrapper";

export const AdminLogin = () => {
  const title = "Login";
  const navigate = useNavigate();
  const service = new ServiceWrapper();
  const token = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).token;

  React.useEffect(() => {
    if (token) {
      window.location.href = "/admin/dashboard";
    }
  }, [token]);

  const [form, setForm] = React.useState({
    email: "",
    password: "",
  });

  React.useEffect(() => {
    document.title = `Ampious : ${title}`;
    document.addEventListener("keyup", (event) => {
      if (event.key === "Enter") {
        loginHandle();
      }
    });
  }, []);

  const loginHandle = async () => {
    if (!form.email || !form.password) {
      Swal.fire(
        "Login Error",
        "Please Enter correct Email and password",
        "error",
      );
    }
    service
      .callPost(`${API_HOSTNAME}/admin/login`, {
        email: form.email,
        password: form.password,
      })
      .then((response: AxiosResponse) => {
        setUserAction(response.data.admin);
        setUserTypeAction("admin");
        setUserTokenAction(response.headers["authorization"]);
        localStorage.setItem("user", JSON.stringify(response.data.admin));

        // localStorage.setItem("gym-staff", JSON.stringify(response.data.staff));

        localStorage.setItem("user-type", "admin");
        localStorage.setItem("token", response.headers["authorization"]);
      })
      .catch((error) => {
        Swal.fire(
          "Login Error",
          "Please Enter correct Email and password",
          "error",
        );
      });
  };

  const setFormValues = (field: string, value: string) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  return (
    <>
      <div className="h-[90vh]">
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div className="col-md-5">
            <div className="card">
              <div className="card-header text-center">
                <img
                  src={logo}
                  alt="ampious-logo"
                  width={"25%"}
                  style={{ alignSelf: "center" }}
                />
              </div>
              <div className="card-header text-center">
                <Typography variant="h4"> Welcome Admin</Typography>
                <Typography variant="h4"> Please Login</Typography>
              </div>
              <div className="card-body">
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    type="text"
                    value={form.email}
                    placeholder="Enter Your Email"
                    className="form-control"
                    onChange={(event) => {
                      setFormValues("email", event.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Password"
                    type="password"
                    value={form.password}
                    placeholder="Enter Your Password"
                    className="form-control my-3"
                    onChange={(event) => {
                      setFormValues("password", event.target.value);
                    }}
                  />
                </FormControl>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      loginHandle();
                    }}
                    sx={{ width: "8rem" }}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
