import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IGymVisitor } from "../interfaces/IGymVisitor";
import { API_HOSTNAME } from "../shared/constants";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../store/store";
import Swal from "sweetalert2";
import { ServiceWrapper } from "../services/serviceWrapper";

interface IVisitorForm {
  onSave: () => void;
  showCancelButton: boolean;
  onCancel: () => void;
}

const VisitorForm = (props: IVisitorForm) => {
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;

  const service = new ServiceWrapper();
  const [objectives, setObjectives] = useState([]);
  const [visitor, setVisitor] = useState<IGymVisitor>({
    gym_id: "",
    name: "",
    email: "",
    phone: "",
    objective: "WEIGHT_LOSS",
    otherInfo: "",
  });

  useEffect(() => {
    if (gym) {
      setVisitor({
        ...visitor,
        gym_id: gym!._id!,
      });
    }
  }, [gym]);

  const sendData = async () => {
    try {
      service
        .callPost(`${API_HOSTNAME}/gym_info/add-gym-visitor`, visitor)
        .then((response) => {
          Swal.fire("Success", "", "success");
          setVisitor({
            ...visitor,
            name: "",
            email: "",
            phone: "",
            objective: "",
            otherInfo: "",
          });
          props.onSave();
        });
    } catch (error) {
      Swal.fire("Error", "", "error");
    }
  };

  const getObjective = async () => {
    const response = await service.callGet(`${API_HOSTNAME}/master/objectives`);

    setObjectives(response.data);
  };

  useEffect(() => {
    getObjective();
  }, []);

  return (
    <Grid container>
      <Grid item>
        <Typography variant="h6" sx={{ textAlign: "left" }}>
          Add Visitor Info
        </Typography>
        <FormControl fullWidth sx={{ marginTop: "1rem" }}>
          {" "}
          <TextField
            label="Name"
            variant="outlined"
            onChange={(e) => {
              setVisitor({ ...visitor, name: e.target.value });
            }}
            placeholder="Enter Name"
            value={visitor.name}
          />
        </FormControl>

        <FormControl sx={{ marginTop: "1rem" }} fullWidth>
          <TextField
            label="Email"
            variant="outlined"
            onChange={(e) => {
              setVisitor({ ...visitor, email: e.target.value });
            }}
            value={visitor.email}
          />
        </FormControl>

        <FormControl sx={{ marginTop: "1rem" }} fullWidth>
          {" "}
          <TextField
            onChange={(e) => {
              setVisitor({ ...visitor, phone: e.target.value });
            }}
            inputProps={{
              maxLength: 10,
            }}
            label="Phone Number"
            variant="outlined"
            value={visitor.phone}
          />
        </FormControl>

        <FormControl fullWidth sx={{ marginTop: "1rem" }}>
          <InputLabel>Objective</InputLabel>
          <Select
            value={visitor.objective}
            label="Staff Id "
            onChange={(event) => {
              setVisitor({ ...visitor, objective: event.target.value });
            }}
          >
            {Object.entries(objectives).map(([key, value]) => (
              <MenuItem value={key}>{value}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ marginTop: "1rem" }} fullWidth>
          <TextareaAutosize
            minRows={2}
            placeholder="Other Information"
            onChange={(e) => {
              setVisitor({ ...visitor, otherInfo: e.target.value });
            }}
            value={visitor.otherInfo}
            className="p-2 rounded-md bg-gray-200"
          />
        </FormControl>
        <Button
          variant="contained"
          fullWidth
          sx={{ marginTop: "1rem" }}
          onClick={sendData}
        >
          Add Visitor
        </Button>
        {props.showCancelButton && (
          <Button
            variant="contained"
            color="error"
            fullWidth
            sx={{ marginTop: "1rem" }}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default VisitorForm;
