import { Action, combineReducers, configureStore } from "@reduxjs/toolkit";
import { IGymOwnerResponse } from "../interfaces/IGymOwner";
import userReducer from "./reducers/gymOwnerReducer";
import { IAmpiousMemberShipInfo, IGymInfoResponse } from "../interfaces";
import { IStaffDetail, IStaffDetailResponse } from "../interfaces/IStaffDetail";

export interface IUserReducer {
  user: IGymOwnerResponse | null;
  staffUser: IStaffDetailResponse | null;
  userType: string;
  token: string;
  memberShipInfo: IAmpiousMemberShipInfo | null;
  gymInfo: IGymInfoResponse | null;
}
export interface BaseAction<T> extends Action<string> {
  type: string;
  payload: T;
}

export interface IGlobalStoreState {
  userReducer: IUserReducer;
}

const rootReducer = combineReducers({
  userReducer: userReducer,
});

export default configureStore({ reducer: rootReducer });
