import { Container, Grid, Typography } from "@mui/material";
import React from "react";

export const PrivacyPolicy = () => {
  return (
    <Container
      style={{
        backgroundColor: "#fff",
        padding: "10px 0",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <img
        src={
          "https://ampious.in/wp-content/uploads/2024/08/cropped-Ampious-transparent-logo-150x150.png"
        }
      />
      <Grid container>
        <Grid item mt={3} paddingLeft={5}>
          <Typography variant="h3"> Privacy Policy</Typography>
          <p>
            At <span className="highlight preview_website_name">Ampious</span>,
            accessible at{" "}
            <span className="highlight preview_website_url">ampious.in</span>,
            one of our main priorities is the privacy of our visitors. This
            Privacy Policy document contains types of information that is
            collected and recorded by{" "}
            <span className="highlight preview_website_name">Ampious</span> and
            how we use it.
          </p>
          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us through email at{" "}
            <span className="highlight preview_email_address">
              support@ampious.in
            </span>
          </p>
          <p>
            This privacy policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in{" "}
            <span className="highlight preview_website_name">Ampious</span>.
            This policy is not applicable to any information collected offline
            or via channels other than this website.
          </p>
          <p>
            <strong>Consent</strong>
          </p>
          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>
          <p>
            <strong>Information we collect</strong>
          </p>
          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
          <p>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>
          <p>
            <strong>How we use your information</strong>
          </p>
          <p>
            We use the information we collect in various ways, including to:
          </p>
          <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
          <br />
          <p>
            <strong>Log Files</strong>
          </p>
          <p>
            <span className="highlight preview_website_name">Ampious</span>{" "}
            follows a standard procedure of using log files. These files log
            visitors when they visit websites. All hosting companies do this and
            a part of hosting services' analytics. The information collected by
            log files include internet protocol (IP) addresses, browser type,
            Internet Service Provider (ISP), date and time stamp, referring/exit
            pages, and possibly the number of clicks. These are not linked to
            any information that is personally identifiable. The purpose of the
            information is for analyzing trends, administering the site,
            tracking users' movement on the website, and gathering demographic
            information.
          </p>
          <h3>Cookies and Web Beacons</h3>
          <br />
          <p>
            Like any other website,{" "}
            <span className="highlight preview_website_name">Ampious</span> uses
            ‘cookies'. These cookies are used to store information including
            visitors' preferences, and the pages on the website that the visitor
            accessed or visited. The information is used to optimize the users'
            experience by customizing our web page content based on visitors'
            browser type and/or other information.
          </p>
          <p>
            <strong>Third-Party Privacy Policies</strong>
          </p>
          <p>
            <span className="highlight preview_website_name">Ampious</span>'s
            Privacy Policy does not apply to other advertisers or websites.
            Thus, we are advising you to consult the respective Privacy Policies
            of these third-party ad servers for more detailed information. It
            may include their practices and instructions about how to opt-out of
            certain options.
          </p>
          <p>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites. What Are Cookies?
          </p>

          <p>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>
          <p>
            <span className="highlight preview_website_name">Ampious</span> does
            not knowingly collect any Personal Identifiable Information from
            children under the age of 13. If you think that your child provided
            this kind of information on our website, we strongly encourage you
            to contact us immediately and we will do our best efforts to
            promptly remove such information from our records.
          </p>
        </Grid>
      </Grid>
    </Container>
  );
};
