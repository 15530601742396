import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDumbbell,
  faHeartPulse,
  faPerson,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import { API_HOSTNAME, THEME_ORANGE } from "../../../shared/constants";
import { IDashboard, ISubscriptionPlans } from "../../../interfaces";
import {
  Alert,
  Button,
  Chip,
  Grid,
  Divider,
  Tooltip,
  Typography,
  Dialog,
  Slide,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import VisitorForm from "../../../components/VisitorForm";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import { IStaffDetail } from "../../../interfaces/IStaffDetail";

window.moment = moment;

export const Dashboard = () => {
  const title = "Dashboard";
  const navigate = useNavigate();
  const service = new ServiceWrapper();

  const memberShip = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).memberShipInfo;
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;
  const [staff, setStaff] = React.useState<IStaffDetail[]>([]);
  const [visitorCount, setVisitorCount] = React.useState(0);
  const [data, setData] = React.useState<IDashboard | null>(null);
  const [showUpGradeModal, setShowUpgradeModal] = React.useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = React.useState<
    ISubscriptionPlans[]
  >([]);

  React.useEffect(() => {
    if (user && gym && gym._id) {
      getDashboardData();
      getStaff();
      getVisitor();
    }
    document.title = title;
  }, [user]);

  const getDashboardData = () => {
    service
      .callGet(`${API_HOSTNAME}/dashboard/get_reports/${user?._id}`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {});
  };

  const getStaff = () => {
    if (gym && gym._id) {
      console.log(staff);
      service
        .callGet(`${API_HOSTNAME}/gym-staff/get-my-staff/${gym!._id}`)
        .then((response) => {
          console.log("Get Staff Response", response);
          setStaff(response.data.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: (params) => {
        return (
          <Tooltip title={`${params.row.first_name} ${params.row.last_name}`}>
            <Chip
              label={`${params.row.first_name} ${params.row.last_name}`}
              color="default"
              style={{ color: "green" }}
            ></Chip>
          </Tooltip>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
    },
    {
      field: "phone_number",
      headerName: "Phone",
      width: 100,
    },
    {
      field: "MemberShip",
      headerName: "MemberShip",
      width: 150,
      renderCell: (params) => {
        return params.row.membership_details ? (
          <Tooltip
            title={`Valid till ${moment(params.row.membership_details.plan_start_date).format("DD-MMM-YYYY")} to ${moment(params.row.membership_details.plan_end_date).format("DD-MMM-YYYY")}`}
          >
            <Chip
              label="Active"
              color="default"
              style={{ color: "green" }}
            ></Chip>
          </Tooltip>
        ) : (
          <Chip
            label="Not Active"
            color="default"
            style={{ color: "red" }}
          ></Chip>
        );
      },
    },
    {
      field: "Action",
      headerName: "actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          sx={{ backgroundColor: "#007bff", color: "white" }}
          onClick={() => {
            navigate(`/gym-owner/customer-vitals/${params.row._id}`);
          }}
        >
          <FontAwesomeIcon icon={faHeartPulse} size="sm" /> Add Vitals
        </Button>
      ),
    },
  ];

  const handlePayment = () => {
    window.alert(
      "Please complete the payment process in new window, you will be updated via mail once subscription starts",
    );
    service
      .callPost(`${API_HOSTNAME}/payments/initiate_subscription`, {
        gym_owner_id: user?._id,
        gym_id: gym?._id,
      })
      .then((response) => {
        console.log("response", response);
        if (response && response.data) {
          setShowUpgradeModal(false);
          window.open(response.data.new_subscription.short_url, "_blank");

          // checkPaymentStatus();
        }
      });
  };

  const initiateUpgrade = () => {
    service.callGet(`${API_HOSTNAME}/admin/get_plans`).then((response) => {
      console.log("response", response.data);
      setSubscriptionPlans(response.data.data);
      setShowUpgradeModal(true);
    });
  };

  const getVisitor = async () => {
    if (gym && gym._id) {
      const response = await service.callGet(
        `${API_HOSTNAME}/gym_info/get-gym-visitors/${gym?._id}`,
      );
      setVisitorCount(response.data.data.length);
    }
  };

  const isTrialActive = (): boolean => {
    console.log("memberShip", memberShip);
    return memberShip?.membership_state === "IN_TRIAL";
  };

  const getTrialMessage = (): string => {
    if (memberShip?.membership_state === "IN_TRIAL") {
      console.log(
        "fioff",
        moment.unix(parseInt(memberShip.membership_start_date)).format(),
      );
      return `Your Trial is active and your subscription will start ${moment.unix(parseInt(memberShip.membership_start_date)).from(moment())} on ${moment.unix(parseInt(memberShip.membership_start_date)).format("DD/MM/YYYY")}`;
    } else {
      return "";
    }
  };

  return (
    <>
      {data?.gymInfo ? (
        <Grid style={{ backgroundColor: "#fff" }}>
          {isTrialActive() && (
            <Alert severity="success">
              <Grid container justifyContent={"space-between"} flex={1}>
                <Grid item>
                  <h6 style={{ fontSize: 14 }}>
                    {getTrialMessage()} &nbsp;
                    <Button
                      onClick={() => navigate("/gym-owner/membership-details")}
                      variant="contained"
                      style={{ backgroundColor: THEME_ORANGE, height: 30 }}
                    >
                      Upgrade Now
                    </Button>
                  </h6>
                </Grid>
              </Grid>
            </Alert>
          )}

          <Grid
            container
            marginTop={5}
            gap={2}
            style={{ backgroundColor: "#fff" }}
            padding="0 5px"
          >
            <Grid container xs={12} sm={12} md={2} lg={2}>
              <div
                className="dash-count "
                onClick={() => navigate("/gym-owner/gym-customers")}
              >
                <div className=" dash-counts">
                  <h4>
                    {data.totalCustomers ? (
                      <CountUp end={data!.totalCustomers} />
                    ) : (
                      0
                    )}
                  </h4>
                  <h5>Customer</h5>
                </div>
                <div className="dash-imgs">
                  <FontAwesomeIcon
                    icon={faUser}
                    size="2xl"
                    style={{ color: "rgba(0,0,0,.15)" }}
                  />
                </div>
              </div>
            </Grid>
            <Grid container xs={12} sm={12} md={2} lg={2}>
              <div
                className="dash-count das1"
                onClick={() => navigate("/gym-owner/workout-manager")}
              >
                <div className="dash-counts">
                  <h4>{data ? <CountUp end={data!.workoutPlans} /> : null}</h4>

                  <h5>Workout Plans</h5>
                </div>
                <div className="dash-imgs">
                  <FontAwesomeIcon
                    icon={faDumbbell}
                    size="lg"
                    style={{ color: "rgba(0,0,0,.15)" }}
                  />
                </div>
              </div>
            </Grid>
            <Grid container xs={12} sm={12} md={3} lg={3}>
              <div
                className="dash-count das4"
                onClick={() => navigate("/gym-owner/staff")}
              >
                <div className="dash-counts">
                  <h4>{staff ? <CountUp end={staff.length} /> : null}</h4>

                  <h5>Staff</h5>
                </div>
                <div className="dash-imgs">
                  <FontAwesomeIcon
                    icon={faUsers}
                    size="lg"
                    style={{ color: "rgba(0,0,0,.15)" }}
                  />
                </div>
              </div>
            </Grid>
            <Grid container xs={12} sm={12} md={2} lg={2}>
              <div
                className="dash-count"
                style={{ backgroundColor: "#e800ac" }}
              >
                <div
                  className="dash-counts"
                  onClick={() => navigate("/gym-owner/gym-customers")}
                >
                  <h4>
                    {data ? <CountUp end={data!.activeMemberShips} /> : null}
                  </h4>

                  <h5> Active Memberships</h5>
                </div>
                <div className="dash-imgs">
                  <FontAwesomeIcon
                    icon={faUsers}
                    size="lg"
                    style={{ color: "rgba(0,0,0,.15)" }}
                  />
                </div>
              </div>
            </Grid>
            <Grid container xs={12} sm={12} md={2} lg={2}>
              <div
                className="dash-count das3"
                onClick={() => navigate("/gym-owner/visitor-management")}
              >
                <div className="dash-counts">
                  <h4>{visitorCount}</h4>

                  <h5>Visitors</h5>
                </div>
                <div className="dash-imgs">
                  <FontAwesomeIcon
                    icon={faPerson}
                    size="lg"
                    style={{ color: "rgba(0, 0, 0, .15)" }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Divider orientation="horizontal"></Divider>
          <Grid container spacing={2} marginTop={5} padding="0 5px">
            <Grid container md={8} xs={12}>
              <Grid container>
                <Grid
                  item
                  flex={0.6}
                  padding={"0px 10px"}
                  sm={12}
                  md={12}
                  xs={12}
                >
                  <Typography variant="h6" sx={{ textAlign: "left" }}>
                    Recently added customers
                  </Typography>
                  <div
                    className="flex justify-center items-center"
                    style={{ marginTop: 16 }}
                  >
                    {data.gymCustomers.length ? (
                      <DataGrid
                        localeText={{
                          noRowsLabel: "This is a custom message :)",
                        }}
                        rows={data.gymCustomers}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                        getRowId={(row) => row._id}
                        style={{ overflow: "hidden" }}
                      />
                    ) : (
                      <Button
                        onClick={() => {
                          navigate("/gym-owner/gym-customers");
                        }}
                      >
                        No Customers added, Add My Customers
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              padding={"0px 30px"}
              margin={"15px 0px"}
              xs={12}
              md={4}
              style={{ height: "100%" }}
            >
              <VisitorForm
                onSave={getVisitor}
                onCancel={() => {}}
                showCancelButton={false}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        // <Alert
        // 	style={{ width: "100%" }}
        // 	severity="warning"
        // 	action={
        // 		<Link className="dropdown-item" to="/gym-owner/gym-info">
        // 			Add Gym Details
        // 		</Link>
        // 	}
        // >
        // 	Please add your Gym details to start
        // </Alert>

        <Container style={{ width: "100%" }}>
          <Grid container>
            <Grid item flex={1}>
              <Alert
                style={{ width: "100%" }}
                severity="warning"
                action={
                  <Button
                    variant="contained"
                    style={{ backgroundColor: THEME_ORANGE }}
                    onClick={() => {
                      navigate("/gym-owner/gym-info");
                    }}
                  >
                    Add Gym Details
                  </Button>
                  // <Link className="dropdown-item" to="/gym-owner/gym-info">

                  // </Link>
                }
              >
                <h6>Please add your Gym details to start</h6>
              </Alert>
            </Grid>
          </Grid>
        </Container>
      )}

      <Dialog
        open={showUpGradeModal}
        style={{ height: "100%", overflow: "auto", width: "100%" }}
      >
        <Grid
          direction={"row"}
          style={{ backgroundColor: "#fff", padding: 10, borderRadius: 10 }}
        >
          <Grid item textAlign={"center"}>
            <h2>Start Subscription</h2>
          </Grid>
          {subscriptionPlans.map((plan) => {
            return (
              <Grid direction={"column"}>
                <Grid item>
                  {plan.features_included.map((feature) => {
                    return (
                      <Grid
                        item
                        alignContent={"flex-start"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                        direction={"column"}
                        style={{
                          backgroundColor: "green",
                          borderRadius: 10,
                          color: "#fff",
                          marginTop: 10,
                          height: 50,
                          padding: "10px 0px",
                          margin: "10px 10px",
                        }}
                      >
                        <Grid item style={{ justifyContent: "flex-start" }}>
                          <p style={{ textAlign: "center" }}>
                            {" "}
                            <CheckCircleIcon />
                            {feature}
                          </p>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Divider />

                <Grid
                  item
                  container
                  mt={2}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <Button
                      onClick={() => {
                        setShowUpgradeModal(false);
                      }}
                      variant="contained"
                      color="inherit"
                      style={{
                        backgroundColor: "#fff",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        navigate("/gym-owner/membership-details");
                      }}
                      variant="contained"
                      color="inherit"
                      style={{
                        backgroundColor: "#FF9F43",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      Subscribe
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Dialog>
    </>
  );
};
