import { Container, Divider, Grid } from "@mui/material";
import GymOwnerLayouts from "./innerPages/gym-owner/layouts";
import logo from "../assets/photo/image-removebg-preview.png";

export const ErrorPage = () => {
  return (
    <Container style={{ backgroundColor: "#fff", padding: "10px 10px" }}>
      <Grid container gap={5}>
        <Grid item justifyContent={"center"}>
          <img src={logo} alt="" width={50} height={50} />
          <h1>
            We ran into some issue, Please refresh the page to visit dashboard
          </h1>
        </Grid>
        <Grid item>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            style={{ color: "#000", width: 10 }}
          />
        </Grid>
        <Grid item>
          <h4>
            If issue continues, then please drop a mail to support@ampious.in or
            send Whatsapp to 91-9026631323
          </h4>
        </Grid>
      </Grid>
    </Container>
  );
};
