import React from "react";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import { IWorkoutPlan, IWorkoutPlanResponse } from "../../../interfaces";
import { API_HOSTNAME } from "../../../shared/constants";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Container,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PublicIcon from "@mui/icons-material/Public";
import ShieldIcon from "@mui/icons-material/Shield";
import { useNavigate, useParams, Link } from "react-router-dom";

import { CustomDialog } from "../../../shared";
import { ServiceWrapper } from "../../../services/serviceWrapper";

export const WorkoutManager = () => {
  const params = useParams();

  const title = "Workout Manager";
  const service = new ServiceWrapper();
  const navigate = useNavigate();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;

  const [showAddPlanModal, setShowAddPlanModal] =
    React.useState<boolean>(false);
  const [majorFocusList, setMajorFocusList] = React.useState<string[]>([]);
  const [workoutPlans, setWorkoutPlans] = React.useState<
    IWorkoutPlanResponse[]
  >([]);
  const [newWorkoutPlanForm, setNewWorkoutPlanForm] =
    React.useState<IWorkoutPlan>({
      plan_name: "",
      gym_id: "",
      is_public: true,
      major_focused: "",
      is_custom_plan: true,
    });

  React.useEffect(() => {
    document.title = `Ampious : ${title}`;
    if (gym) {
      setNewWorkoutPlanForm({
        ...newWorkoutPlanForm,
        gym_id: gym!._id!,
      });
      getMajorFocusList();
      gym && gym._id && getWorkoutPlans();
    }
  }, [user, gym]);

  const getMajorFocusList = () => {
    service
      .callGet(`${API_HOSTNAME}/master/workout-major-focus`)
      .then((response) => {
        setMajorFocusList(response.data);
      })
      .catch((error) => {});
  };

  const getWorkoutPlans = () => {
    service
      .callGet(`${API_HOSTNAME}/workouts/my_workout_plans/${gym!._id}`)
      .then((response) => {
        setWorkoutPlans(response.data.data);
      })
      .catch((error) => {});
  };

  const createNewPlan = () => {
    service
      .callPost(
        `${API_HOSTNAME}/workouts/create-workout-plan`,
        newWorkoutPlanForm,
      )
      .then((response) => {
        getWorkoutPlans();
        setShowAddPlanModal(false);
      })
      .catch((error) => {});
  };

  const navigateToWorkoutEditor = (planId: string, is_custom_plan: boolean) => {
    if (!is_custom_plan) {
      navigate(`/gym-owner/workout-plan-editor/${planId}`);
    } else {
      navigate(`/gym-owner/custom-workout-plan/${planId}`);
    }
  };
  const deleteWorkoutPlan = (planId: string) => {
    service
      .callDelete(
        `${API_HOSTNAME}/workouts/delete_workout_plan?plan_id=${planId}`,
        {},
      )
      .then(() => {
        getWorkoutPlans();
      })
      .catch((error) => {});
  };

  return (
    <div className="h-screen" style={{ backgroundColor: "#fff" }}>
      {gym ? (
        <>
          <div className="container-fluid">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1 align-items-center">
                <div className="">{title} </div>
                <Grid
                  item
                  style={{ paddingRight: 10 }}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Button
                    variant="outlined"
                    style={{ height: "40px" }}
                    onClick={() => {
                      setShowAddPlanModal(true);
                    }}
                  >
                    Create new Plan
                  </Button>
                </Grid>
              </div>

              <CustomDialog
                isModalOpen={showAddPlanModal}
                onSaveClick={createNewPlan}
                onCancelClick={function (): void {
                  setShowAddPlanModal(false);
                }}
                headerText={"Create a new Plan"}
                fullWidth={false}
                maxWidth={"sm"}
              >
                <Grid direction={"column"} container className={"mt-3 mb-3"}>
                  <Grid item alignItems={"center"}>
                    <TextField
                      fullWidth
                      placeholder="Create new Plan"
                      label="Create new Plan"
                      variant="outlined"
                      style={{ marginRight: 20 }}
                      onChange={(e) => {
                        setNewWorkoutPlanForm({
                          ...newWorkoutPlanForm,
                          plan_name: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item className={"mt-3"}>
                    <FormControl fullWidth style={{ width: "100%" }}>
                      <InputLabel>Major focus of plan</InputLabel>

                      <Select
                        value={newWorkoutPlanForm?.major_focused}
                        label="Day"
                        onChange={(event) => {
                          setNewWorkoutPlanForm({
                            ...newWorkoutPlanForm,
                            major_focused: event.target.value.toString(),
                          });
                        }}
                      >
                        {majorFocusList.map((majorFocus) => {
                          return (
                            <MenuItem value={majorFocus}>{majorFocus}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item alignItems={"center"} className={"mt-3"}>
                    <Tooltip
                      title={
                        "Making a workout public will make this visible to customers in the system and in app, this will increase reach of you and your gym"
                      }
                    >
                      <FormControlLabel
                        label="Make this workout public"
                        control={
                          <Checkbox
                            checked={newWorkoutPlanForm.is_public}
                            onChange={(e) => {
                              setNewWorkoutPlanForm({
                                ...newWorkoutPlanForm,
                                is_public: e.target.checked,
                              });
                            }}
                          />
                        }
                      />
                    </Tooltip>
                  </Grid>
                  {/* <Grid item className={"mt-3"}>
                    <FormControl fullWidth style={{ width: "100%" }}>
                      <InputLabel>Custom Plan ?</InputLabel>

                      <Select
                        value={
                          newWorkoutPlanForm?.is_custom_plan == true
                            ? "yes"
                            : "no"
                        }
                        label="Day"
                        onChange={(event) => {
                          setNewWorkoutPlanForm({
                            ...newWorkoutPlanForm,
                            is_custom_plan:
                              event.target.value === "yes" ? true : false,
                          });
                        }}
                      >
                        <MenuItem value={"yes"}>
                          Yes,I will create my own workouts plan
                        </MenuItem>
                        <MenuItem value={"no"}>
                          No,I will use Ampious workout editor to create
                          workouts
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                </Grid>
              </CustomDialog>

              <Grid
                container
                direction="row"
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                {workoutPlans.map((plan) => {
                  return (
                    <Card
                      sx={{
                        minWidth: 285,
                        margin: "10px",
                        flexWrap: "nowrap",
                        overFlowY: "hidden",
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="p"
                          fontSize={"10"}
                        >
                          {plan.is_public ? <PublicIcon /> : <ShieldIcon />}
                          <Typography
                            gutterBottom
                            component="span"
                            fontSize={"4"}
                          >
                            {plan.plan_name}
                          </Typography>
                        </Typography>
                        <Typography variant="h6" fontSize={12}>
                          {plan.is_custom_plan ? "Custom Plan" : ""}
                        </Typography>
                        {/* <Typography variant="body2" color="text.secondary">
													{plan.exercises_count} exercises
												</Typography> */}
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            navigateToWorkoutEditor(
                              plan._id,
                              plan.is_custom_plan,
                            );
                          }}
                          startIcon={<EditIcon />}
                          size="small"
                        >
                          Details
                        </Button>
                        <Button
                          variant="outlined"
                          color={"error"}
                          onClick={() => {
                            deleteWorkoutPlan(plan._id);
                          }}
                          startIcon={<DeleteIcon />}
                          size="small"
                        >
                          Delete
                        </Button>

                        {/* <Button variant="outlined" color={'success'} onClick={() => {
                                            navigateToWorkoutCustomerRelation(plan._id)
                                        }} startIcon={<SettingsEthernetIcon />} size="small">Assigned Customers</Button> */}
                      </CardActions>
                    </Card>
                  );
                })}
              </Grid>
            </div>
          </div>
        </>
      ) : (
        <Alert severity="error">
          Please add your gym details to add workouts{" "}
          <Link className="dropdown-item" to="/gym-info">
            Add Gym Details
          </Link>
        </Alert>
      )}
    </div>
  );
};
