import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import React from "react";
import { IGymInfo, IGymInfoResponse } from "../../../interfaces";
import axios, { AxiosResponse } from "axios";
import { API_HOSTNAME } from "../../../shared/constants";
import Swal from "sweetalert2";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import { Button, TextField } from "@mui/material";
import { setGymInfoAction, setMemberShipInfoAction } from "../../../store";

export const GymInfo = () => {
  const title = "Gym information";
  const serviceWrapper = new ServiceWrapper();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const [hasGymRegisted, setHasGymRegistered] = React.useState<boolean>(false);
  const [registeredGymId, setRegisteredGymId] = React.useState<string>("");
  const [gymForm, setGymForm] = React.useState<IGymInfoResponse>({
    _id: null,
    name: "",
    address: "",
    email: "",
    phone_no: "",
    gym_owner_id: "",
    logo: "",
    google_maps_link: "",
    facebook_page_link: "",
    instagram_page_link: "",
  });

  React.useEffect(() => {
    document.title = `Ampious : ${title}`;

    user && getGymInfo();
    setGymForm({
      ...gymForm,
      gym_owner_id: user?._id!,
    });
  }, [user]);

  const getGymInfo = () => {
    serviceWrapper
      .callGet(`${API_HOSTNAME}/gym_info/my_gyms?gym_owner_id=${user?._id}`)
      .then((response) => {
        console.log(response.data.data);
        const existingGymInfo: IGymInfoResponse = response.data.data;
        if (existingGymInfo) {
          setGymForm({
            _id: existingGymInfo._id,
            name: existingGymInfo.name,
            address: existingGymInfo.address,
            email: existingGymInfo.email,
            phone_no: existingGymInfo.phone_no,
            gym_owner_id: existingGymInfo.gym_owner_id,
            logo: existingGymInfo.logo,
            google_maps_link: existingGymInfo.google_maps_link,
            facebook_page_link: existingGymInfo.facebook_page_link,
            instagram_page_link: existingGymInfo.instagram_page_link,
          });
        }
      });
  };

  const updateForm = (field: string, value: string) => {
    setGymForm({
      ...gymForm,
      [field]: value,
    });
  };

  const submit = () => {
    const url =
      gymForm && gymForm._id
        ? `${API_HOSTNAME}/gym_info/update-gym/${gymForm._id}`
        : `${API_HOSTNAME}/gym_info/register`;
    const service = new ServiceWrapper();
    if (gymForm._id) {
      service
        .callPut(url, {
          ...gymForm,
        })
        .then((response) => {
          // setMemberShipInfoAction(response.data.membershipInfo);
          // setGymInfoAction(response.data.gymInfo);
          Swal.fire({
            icon: "success",
            title: `gym details have been updated`,
            showCancelButton: true,
          }).then((response) => {
            console.log("response", response);
          });
          getGymInfo();
        });
    } else {
      service
        .callPost(`${API_HOSTNAME}/gym_info/register`, gymForm)
        .then((response: AxiosResponse) => {
          console.log("response", response);
          Swal.fire("Gym Added", "", "success");
          localStorage.setItem(
            "gym_info",
            JSON.stringify(response.data.gymInfo),
          );
          localStorage.setItem(
            "member_ship_details",
            JSON.stringify(response.data.membershipInfo),
          );
          setMemberShipInfoAction(response.data.membershipInfo);
          setGymInfoAction(response.data.gymInfo);
        })
        .catch(() => {
          Swal.fire({
            icon: "warning",
            title: `Please add all the required fields`,
            showCancelButton: true,
            confirmButtonText: "Yes",
          }).then((response) => {
            console.log("response", response);
          });
        });
    }
  };

  const uploadFile = (event: any) => {
    console.log("ev", event.target.files[0]);

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    reader.onload = () => {
      console.log("called: ", reader);
      console.log(reader.result);
      if (reader.result) {
        serviceWrapper
          .uploadMedia(reader.result?.toString())
          .then((response) => {
            console.log("response", response.data.data.secure_url);
            setGymForm({
              ...gymForm,
              logo: response.data.data.secure_url,
            });
          });
      }
    };
  };

  return (
    <>
      <div className="h-screen ">
        {/* <Breadcrumb title={title} brad={brad} /> */}
        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title} </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label>
                  <b>Name of Gym as registered*</b>
                </label>
                <TextField
                  disabled={gymForm._id ? true : false}
                  title={
                    gymForm._id ? "GYM NAME CANNOT BE CHANGED ONCE ADDED" : ""
                  }
                  error={!gymForm.name}
                  type="text"
                  placeholder="Enter Gym Name"
                  value={gymForm!.name}
                  className="form-control"
                  onChange={(e) => {
                    updateForm("name", e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label>
                  <b>Address of Gym*</b>
                </label>
                <TextField
                  required
                  error={!gymForm.address}
                  type="text"
                  placeholder="Address of gym"
                  value={gymForm!.address}
                  className="form-control"
                  onChange={(e) => {
                    updateForm("address", e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label>
                  <b>Gym Email*</b>
                </label>
                <TextField
                  error={!gymForm.email}
                  type="text"
                  placeholder="Enter email of gym"
                  value={gymForm!.email}
                  className="form-control"
                  onChange={(e) => {
                    updateForm("email", e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label>
                  <b>Gym Phone No*</b>
                </label>
                <TextField
                  error={!gymForm.phone_no}
                  inputProps={{
                    maxLength: 12,
                  }}
                  type="text"
                  placeholder="Enter Phone of gym"
                  value={gymForm!.phone_no}
                  className="form-control"
                  onChange={(e) => {
                    updateForm("phone_no", e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label>
                  <b>Google maps link</b>
                </label>
                <TextField
                  type="text"
                  placeholder="Enter Google map link of gym"
                  value={gymForm.google_maps_link}
                  className="form-control"
                  onChange={(e) => {
                    updateForm("google maps link", e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label>
                  <b>facebook page link</b>
                </label>
                <TextField
                  type="text"
                  placeholder="Enter facebook page link of gym"
                  value={gymForm.facebook_page_link}
                  className="form-control"
                  onChange={(e) => {
                    updateForm("facebook_page_link", e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label>
                  <b>Instagram Page Link</b>
                </label>
                <TextField
                  type="text"
                  placeholder="Enter instagram page link of gym"
                  value={gymForm.instagram_page_link}
                  className="form-control"
                  onChange={(e) => {
                    updateForm("instagram_page_link", e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label>
                  <b>Add Gym logo</b>
                </label>
                <TextField
                  type="file"
                  className="form-control"
                  onChange={uploadFile}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Button
                  fullWidth
                  onClick={submit}
                  className="btn btn-primary btn-sm mt-3"
                  style={{ color: "#fff" }}
                >
                  {gymForm ? "Update Gym Details" : "Add Gym Detail"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
