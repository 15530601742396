import dayjs from "dayjs";
import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { IStaffAttendence } from "../../../interfaces";
import { API_HOSTNAME } from "../../../shared/constants";
import { IGlobalStoreState } from "../../../store/store";
import { TransitionProps } from "@mui/material/transitions";
import { IStaffDetail } from "../../../interfaces/IStaffDetail";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import { Calendar, momentLocalizer, View } from "react-big-calendar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "react-big-calendar/lib/css/react-big-calendar.css";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
} from "@mui/material";

window.moment = moment;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const StaffDashboard = () => {
  const title = "Dashboard";
  const service = new ServiceWrapper();
  const localizer = momentLocalizer(moment);
  const [showAddAttendanceDialog, setShowAddAttendanceDialog] =
    React.useState<boolean>(false);
  const [staffData, setStaffData] = React.useState<IStaffDetail | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const staff = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).staffUser;
  const gymInfo = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;
  const [staffAttendance, setStaffAttendance] =
    React.useState<IStaffAttendence | null>(null);
  const [calenderEvents, setCalenderEvents] = React.useState<
    {
      start: any;
      end: any;
      title: string;
    }[]
  >([]);

  React.useEffect(() => {
    if (staff && gymInfo) {
      setStaffAttendance({
        ...staffAttendance!,
        gym_id: gymInfo!._id!,
        staff_id: staff._id!,
      });
    }
  }, [gymInfo, staff]);

  React.useEffect(() => {
    document.title = "Ampious: " + title;
    if (staff) {
      getAttendaceInfo();
      setStaffData(staff);
    }
  }, [staff]);

  React.useEffect(() => {
    selectedDate &&
      setStaffAttendance({
        ...staffAttendance!,
        date_of_attendance: selectedDate,
      });
  }, [selectedDate]);

  const addAttendance = () => {
    service
      .callPost(`${API_HOSTNAME}/gym-staff/add_staff_attendance`, {
        ...staffAttendance,
        staff_id: staff!._id,
        gym_id: gymInfo!._id,
      })
      .then((response) => {
        console.log("response", response);
        setSelectedDate(new Date());
        getAttendaceInfo();
        setShowAddAttendanceDialog(false);
      });
  };

  const getAttendaceInfo = () => {
    staff &&
      service
        .callGet(`${API_HOSTNAME}/gym-staff/get-my-attendance/${staff._id}`)
        .then((response) => {
          let event = {
            start: new Date(),
            end: new Date(),
            title: "",
          };
          let events: any = [];
          for (let i = 0; i < response.data.length; i++) {
            let selectedObject = response.data[i];
            console.log(
              " moment(selectedObject.date_of_attendance)",
              moment(selectedObject.date_of_attendance).toDate(),
            );
            // event["start"] = moment(selectedObject.date_of_attendance).toDate();
            // event["end"] = moment(selectedObject.date_of_attendance).toDate();
            // event["title"] = selectedObject.attendance_status;
            // events.push(event);
            events.push({
              start: response.data[i].date_of_attendance,
              end: response.data[i].date_of_attendance,
              title: response.data[i].attendance_status,
              id: response.data[i]._id,
            });
          }
          console.log("events", events);
          setCalenderEvents(events);
        });
  };

  return (
    <>
      <Dialog
        open={showAddAttendanceDialog}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setShowAddAttendanceDialog(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add Attendace"}</DialogTitle>

        <DialogContent>
          <Grid container direction="column" gap={2}>
            <Grid item flex={1}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  fullWidth
                  value={staffAttendance?.attendance_status}
                  label="Gender"
                  onChange={(event) => {
                    setStaffAttendance({
                      ...staffAttendance!,
                      attendance_status: event.target.value
                        ? event.target.value
                        : "",
                    });
                  }}
                >
                  <MenuItem value={"present"}>Present</MenuItem>
                  <MenuItem value={"absent"}>Absent</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  value={dayjs(staffAttendance?.date_of_attendance)}
                  label="Date of Attendance"
                  onChange={(value) => {
                    value &&
                      setStaffAttendance({
                        ...staffAttendance!,
                        date_of_attendance: value.toDate(),
                      });
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={addAttendance}>
            Save Attendance
          </Button>
          <Button
            onClick={() => {
              setShowAddAttendanceDialog(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container padding={"2px 2px"} direction="column" gap={2}>
        <Grid item>
          <h3>
            Welcome {staff?.first_name} {staff?.last_name}
          </h3>
        </Grid>

        <Grid item gap={2}>
          <h5>Here is your attendance details</h5>
          <Calendar
            showMultiDayTimes
            defaultView="month"
            doShowMoreDrillDown={false}
            onView={(view: View) => {
              console.log("view", view);
            }}
            view="month"
            localizer={localizer}
            events={calenderEvents}
            selectable
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            onSelectSlot={(e) => {
              console.log("in slot", e);
              setShowAddAttendanceDialog(true);
              setSelectedDate(e.start);
            }}
            eventPropGetter={(event) => {
              const backgroundColor =
                event.title === "present" ? "green" : "red";
              return { style: { backgroundColor } };
            }}
            onSelectEvent={(e) => {
              console.log(e);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
