import React from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register({
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
});
ChartJS.defaults.backgroundColor = "#9BD0F5";
const Graph = (data: any) => {
  console.log("This is Prop", data);
  const options = {
    responsive: true,
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Weight Progress",
    },
  };
  const dataValue = {
    labels: ["0-5", "5-10", "10-15", "15-20", "20-25", "25-30"],
    datasets: [
      {
        label: "Weight",
        data: data.data,
        borderColor: "cyan",
      },
    ],
  };

  return (
    <div className="h-full w-full mt-4 mb-4 p-2 flex justify-between items-center gap-24">
      <Line options={options} data={dataValue} />
      {/* <Line options={options} data={data} /> */}
    </div>
  );
};

export default Graph;
