export const ROOT_HOSTNAME = `${process.env.REACT_APP_API_HOSTNAME}`;
export const API_HOSTNAME = `${ROOT_HOSTNAME}/api`;
export const PRIMARY_COLOR = "#4723d9";
export const MOTIVES = [
  { label: "Weight loss", value: "weight_loss" },
  { label: "Weight gain", value: "weight_gain" },
  { label: "General fitness", value: "general_fitness" },
];

export const THEME_ORANGE = "#FF9F43";
export const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

export const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

export const customerFields = [
  {
    // Visible in table header and when matching columns.
    label: "First Name",
    // This is the key used for this field when we call onSubmit.
    key: "first_name",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["first name", "first"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Stephanie",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "Name is required",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  {
    // Visible in table header and when matching columns.
    label: "Last Name",
    // This is the key used for this field when we call onSubmit.
    key: "last_name",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["last name", "last", "sirname"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Stephanie",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "Name is required",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  {
    // Visible in table header and when matching columns.
    label: "Email",
    // This is the key used for this field when we call onSubmit.
    key: "email",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["email", "mail", "email id"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Stephanie",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "Email is required",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  {
    // Visible in table header and when matching columns.
    label: "Phone",
    // This is the key used for this field when we call onSubmit.
    key: "phone_number",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["Phone", "mobile number", "mobile"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Stephanie",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "Phone is required",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
] as const;

export const TinyMCE = {
  plugins: [
    "autolink",
    "autoresize",
    "codesample",
    "link",
    "lists",
    "media",
    "table",
    "image",
    "quickbars",
    "codesample",
    "help",
  ],
  toolbar:
    "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify quicktable image media codesample undo redo | inserttable | cell row column deletetable | help",
};
