import { Container } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import { API_HOSTNAME } from "../../../shared/constants";
import { ServiceWrapper } from "../../../services/serviceWrapper";

export const AdminDashboard = () => {
  const title = "Dashboard";
  const service = new ServiceWrapper();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const [dashboardData, setDashboardData] = React.useState({
    totalUsers: 0,
    totalGyms: 0,
    totalStaff: 0,
    totalMails: 0,
    totalWA: 0,
  });

  React.useEffect(() => {
    document.title = "Ampious: " + title;
    if (user) {
      getDashboard();
    }
  }, [user]);

  const getDashboard = () => {
    service.callGet(`${API_HOSTNAME}/admin/dashboard-data`).then((response) => {
      console.log("response", response);
      setDashboardData(response.data);
    });
  };

  return (
    <Container>
      <h1>Admin</h1>
      <p> totalUsers : {dashboardData.totalUsers}</p>
      <p> totalGyms : {dashboardData.totalGyms}</p>
      <p> totalStaff : {dashboardData.totalStaff}</p>
      <p> totalMails : {dashboardData.totalMails}</p>
      <p> totalWA : {dashboardData.totalWA}</p>
    </Container>
  );
};
