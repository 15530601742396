import {
  Button,
  Container,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { IVisitor } from "../../../interfaces/IVisitor";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import { API_HOSTNAME, THEME_ORANGE } from "../../../shared/constants";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Mail, WhatsApp } from "@mui/icons-material";

export const Visitor = () => {
  const title = "Visitors";
  const service = new ServiceWrapper();
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;
  const [visitorData, setVisitorData] = React.useState<IVisitor[]>([]);

  const getVisitorData = async () => {
    const response = await service.callGet(
      `${API_HOSTNAME}/gym_info/get-gym-visitors/${gym?._id}`,
    );
    console.log(response.data);
    setVisitorData(response.data.data);
  };

  useEffect(() => {
    gym && gym._id && getVisitorData();
  }, [gym]);

  console.log("This is user ", gym?._id);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
    },
    {
      field: "objective",
      headerName: "Objective",
      width: 200,
    },
    {
      field: "otherInfo",
      headerName: "Other Info",
      width: 200,
    },
    {
      field: "follow up",
      headerName: "Follow up",
      width: 200,
      renderCell: (params) => {
        return (
          <Grid container justifyContent={"flex-start"}>
            {params.row.phone && (
              <Button variant="text" style={{}}>
                {<WhatsApp />}
              </Button>
            )}
            {params.row.email && (
              <Button variant="text" style={{}}>
                {<Mail />}
              </Button>
            )}
          </Grid>
        );
      },
    },
  ];
  return (
    <div className="h-screen" style={{ backgroundColor: "#fff" }}>
      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <div className="card-header d-flex justify-content-between border-bottom pb-1">
          <Grid
            container
            padding={"0.5rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>{title}</Grid>
          </Grid>
        </div>

        <Grid style={{ backgroundColor: "#fff" }}>
          <FormControl fullWidth>
            <DataGrid
              rows={visitorData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              sx={{ padding: "1rem" }}
              getRowId={(row) => row._id}
            />
          </FormControl>
        </Grid>
      </div>
    </div>
  );
};
