import React from "react";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Slide,
  Tooltip,
  Divider,
  TextField,
  Checkbox,
} from "@mui/material";
import {
  IGymCustomer,
  IGymCustomerMemberShipResponse,
} from "../../../interfaces";
import axios from "axios";
import {
  API_HOSTNAME,
  ROOT_HOSTNAME,
  THEME_ORANGE,
} from "../../../shared/constants";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { TransitionProps } from "@mui/material/transitions";
import Swal from "sweetalert2";
import moment from "moment";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import { NewCustomerModal } from "../../../shared/NewCustomerModal";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const GymCustomer = () => {
  const title = "Gym Customers";
  const navigate = useNavigate();
  const service = new ServiceWrapper();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;

  const [showAddCustomerModal, setShowAddCustomerModal] =
    React.useState<boolean>(false);
  const [showAddBulkCustomerModal, setShowAddBulkCustomerModal] =
    React.useState<boolean>(false);
  const [bulkFile, setBulkFile] = React.useState<File | null>(null);
  const [searchKeyword, setSearchKeyword] = React.useState<string>("");
  const [isSheetModelOpen, setIsSheetModalOpen] =
    React.useState<boolean>(false);

  const [
    shouldShowCustomersWithActivePlan,
    setShouldShowCustomersWithActivePlan,
  ] = React.useState<boolean>(false);

  const [customer, setCustomer] = React.useState<IGymCustomer>({
    first_name: "",
    last_name: "",
    gender: "",
    email: "",
    motive: "",
    gym_id: "",
    age: "",
    dob: "",
    membership_status: "active",
    membership_start_date: "",
    membership_end_date: "",
    phone_number: "",
    other_membership_detail: "",
    plan_id: "",
  });

  const [customers, setCustomers] = React.useState<
    IGymCustomerMemberShipResponse[]
  >([]);

  React.useLayoutEffect(() => {
    document.title = `Ampious : ${title}`;
  }, []);

  React.useEffect(() => {
    document.title = `Ampious : ${title}`;
  }, []);

  React.useEffect(() => {
    gym && gym!._id && getAllCustomers();
  }, [gym]);

  const getAllCustomers = (reset?: boolean) => {
    if (gym && gym!._id) {
      if (reset) {
        service
          .callGet(
            `${API_HOSTNAME}/gym_customer/my_customers?gym_id=${gym!._id}`,
          )
          .then((response) => {
            setCustomers(response.data);
          })
          .catch((error) => {});
      } else {
        axios
          .get(
            `${API_HOSTNAME}/gym_customer/my_customers?gym_id=${gym!._id}&keyword=${searchKeyword}&show_customers_with_active_plan=${shouldShowCustomersWithActivePlan}`,
          )
          .then((response) => {
            setCustomers(response.data);
          })
          .catch((error) => {});
      }
    }
  };

  React.useEffect(() => {
    getAllCustomers();
  }, [shouldShowCustomersWithActivePlan]);

  const columns: GridColDef<IGymCustomerMemberShipResponse>[] = [
    {
      field: "first_name",
      headerName: "First Name ",
      width: 100,
      renderCell: (params) => {
        return (
          <Link
            component={"a"}
            onClick={() => {
              navigate(`/gym-owner/customer-detail/${params.row._id}`);
            }}
          >
            {params.row.first_name} {params.row.last_name}
          </Link>
        );
      },
    },
    { field: "last_name", headerName: "Last Name", width: 100 },
    { field: "email", headerName: "Email", width: 100 },
    { field: "phone_number", headerName: "Phone Number", width: 200 },
    {
      field: "other_membership_detail",
      headerName: "Other Membership Detail",
      width: 200,
    },
    {
      field: "membership_status",
      headerName: "Membership Status",
      width: 200,
      renderCell: (params) => {
        return params.row.membership_status.toLowerCase() === "active" ? (
          <Tooltip
            title={`Valid till ${moment(params.row.membership_start_date).format("DD-MMM-YYYY")} to ${moment(params.row.membership_end_date).format("DD-MMM-YYYY")}`}
          >
            <Chip
              label="Active"
              color="default"
              style={{ color: "green" }}
            ></Chip>
          </Tooltip>
        ) : (
          <Chip
            label={params.row.membership_status}
            color="default"
            style={{ color: "red" }}
          ></Chip>
        );
      },
    },
    {
      field: "created_on",
      headerName: "Registered on",
      width: 200,
      renderCell: (params) => {
        return `${moment(params.row.created_on).format("DD-MMM-YYYY")}`;
      },
    },
  ];

  const downloadSampleFile = () => {
    window.open(`${ROOT_HOSTNAME}/sample.csv`, "_blank");
  };

  const uploadBulkData = () => {
    setIsSheetModalOpen(false);
    // get the selected file from the input
    const file = bulkFile;
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file!);
    // make a POST request to the File Upload API with the FormData object and Rapid API headers
    axios
      .post(`${API_HOSTNAME}/gym_customer/bulk_import/${gym!._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // handle the response
        console.log(response);
        setBulkFile(null);
        Swal.fire(
          "Customers added Successfully",
          "Please come back and check in sometime",
          "success",
        );

        // getAllCustomers();
        // Swal.fire("Customers added", "", "info");
      })
      .catch((error) => {
        // handle errors
        console.log(error);
        Swal.fire(
          "Customers not added",
          "Some email/phone already exists, please udpate sheet and try again",
          "error",
        );
      });
  };

  return (
    <div className="h-screen">
      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <div className="card-header d-flex justify-content-between border-bottom pb-1">
          <div>{title} </div>
        </div>

        <Grid container padding={"0 10px"}>
          <Grid
            item
            container
            justifyContent="space-between"
            direction={"column"}
          >
            <Grid item>
              <h2 className="mt-3 mb-3">Customers</h2>
            </Grid>
            <Grid item container gap={2}>
              <Grid item flex={1}>
                <TextField
                  inputProps={{
                    style: {
                      height: 5,
                    },
                  }}
                  fullWidth
                  placeholder="Search customers"
                  id="input-with-icon-adornment"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={searchKeyword.length < 4}
                  onClick={() => getAllCustomers()}
                >
                  {/* <SearchIcon /> */}
                  Search
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  onClick={() => getAllCustomers(true)}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction={"row"}
            justifyContent={"flex-end"}
            margin={"10px 0"}
            gap={2}
          >
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  setShowAddCustomerModal(true);
                }}
              >
                Add Customer
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  setBulkFile(null);
                  setIsSheetModalOpen(true);
                }}
              >
                Add Bulk Customer
              </Button>
            </Grid>
          </Grid>

          <Divider />

          {customers && (
            <Grid item container xs={12} md={12} gap={2}>
              <Grid
                item
                container
                justifyContent={"space-between"}
                direction={"row-reverse"}
              >
                <Grid item alignContent={"center"}>
                  <h5>{customers.length} customers</h5>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    label="Show only customers that have active plans"
                    control={
                      <Checkbox
                        checked={shouldShowCustomersWithActivePlan}
                        onChange={(e) => {
                          setShouldShowCustomersWithActivePlan(
                            e.target.checked,
                          );
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <DataGrid
                  rows={customers}
                  columns={columns}
                  paginationMode="client"
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 20 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  getRowId={(row) => row._id!}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>

      <NewCustomerModal
        showAddCustomerModal={showAddCustomerModal}
        onCloseModal={function (): void {
          getAllCustomers();
          setShowAddCustomerModal(false);
        }}
      />

      <Dialog
        open={showAddBulkCustomerModal}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setShowAddBulkCustomerModal(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add Bulk Customers"}</DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item container>
              <Grid item alignSelf={"center"}>
                <input
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  accept="*/*"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setBulkFile(event.target.files![0]);
                  }}
                />
              </Grid>
              <Grid item>
                <h6 className="ml-3">
                  Upload CSV File(
                  <Button onClick={downloadSampleFile}>
                    Download sample file
                  </Button>
                  )
                </h6>
              </Grid>
              <Grid item className="mt-3"></Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className="mt-3"
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
          >
            <label style={{ display: "flex" }} htmlFor="raised-button-file">
              <Button fullWidth variant="contained" component="span">
                Upload File
              </Button>
            </label>
            {bulkFile && (
              <p>
                File Upload successfully, Click on 'Add Customers' to add
                customers
              </p>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowAddBulkCustomerModal(false);
              uploadBulkData();
            }}
          >
            Add Customers
          </Button>
          <Button
            onClick={() => {
              setShowAddBulkCustomerModal(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isSheetModelOpen}
        TransitionComponent={Transition}
        maxWidth="xl"
        onClose={() => {}}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Bulk Import"}</DialogTitle>
        <DialogContent>
          <Divider />
          <p>
            Download sample file and upload your customer details in the
            required format
          </p>
          <Grid container direction={"column"} flex={1} gap={2}>
            <Grid item container direction="row-reverse">
              <Grid item flex={0.5}>
                <Button
                  color="inherit"
                  style={{ backgroundColor: "#FF9F43", color: "#fff" }}
                  variant="text"
                  onClick={() => {
                    downloadSampleFile();
                  }}
                >
                  Download Sample sheet
                </Button>
              </Grid>
              <Grid item flex={0.5}>
                <FormControl>
                  <input
                    id="raised-button-file"
                    type="file"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setBulkFile(event.target.files![0]);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Divider />
            <Grid item justifyContent={"center"} alignContent={"center"}>
              <p>
                If you want to upload manually, then please drop a mail to{" "}
                <br /> <b>support@ampious.in</b> with the list of you customer,
                email and gym name
              </p>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            style={{ backgroundColor: THEME_ORANGE, color: "#fff" }}
            onClick={uploadBulkData}
          >
            Upload
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setIsSheetModalOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
