import "./App.css";
import { ErrorBoundary } from "react-error-boundary";
import { Container } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// import Footer from "./publicPages/Footer";

export const BaseComponent = () => {
  return (
    <>
      <ErrorBoundary
        fallback={
          <Container style={{ height: "100%", padding: 0 }}>
            <h1>Some error occured</h1>
          </Container>
        }
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
};
