import {
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_HOSTNAME } from "../shared/constants";
import logo from "../assets/photo/image-removebg-preview.png";

export const ForgotPassword = () => {
  const title = "Forgot Password";
  const naviagte = useNavigate();
  const [email, setEmail] = useState("");
  document.title = `Ampious ${title}`;

  const recoverPswHandle = async () => {
    if (email) {
      try {
        const response = await axios.post(
          `${API_HOSTNAME}/gym-owner/forgot-password`,
          { email },
        );
        console.log(response);
        Swal.fire("Password Send", "", "success");
      } catch (error) {
        Swal.fire("Error", "Email not found", "error");
      }
    } else {
      Swal.fire("Error", "Please provide Email", "error");
    }
  };
  return (
    <>
      <Container
        sx={{
          display: "flex",
          height: "90vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="card" style={{ padding: "10px" }}>
          <div className="card-header text-center">
            <img
              src={logo}
              alt="ampious-logo"
              width={"25%"}
              style={{ alignSelf: "center" }}
            />
          </div>
          <div className="w-96 sm:w-[500px] flex justify-center items-center flex-col rounded-md h-fit">
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Forgot Password
            </Typography>
            <FormControl fullWidth>
              <TextField
                value={email}
                label="Username or Email"
                sx={{ margin: "1rem" }}
                required={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <Container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {" "}
              <Button
                sx={{ width: "10rem", height: "2rem", fontSize: "12px" }}
                variant="contained"
                onClick={recoverPswHandle}
              >
                Get Password
              </Button>
              <Button
                onClick={() => naviagte("/public/login")}
                variant="outlined"
              >
                Login
              </Button>
            </Container>
          </div>
        </div>
      </Container>
    </>
  );
};
