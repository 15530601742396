import { BaseAction, IUserReducer } from "../store";

const initialState: IUserReducer = {
  user: null,
  token: "",
  memberShipInfo: null,
  gymInfo: null,
  userType: "",
  staffUser: null,
};

const userReducer = (state = initialState, action: BaseAction<any>) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload };
    case "SET_TOKEN":
      return { ...state, token: action.payload };
    case "LOGOUT_USER": {
      return {
        ...state,
        user: null,
      };
    }
    case "SET_MEMBERSHIP_DETAIL": {
      return {
        ...state,
        memberShipInfo: action.payload,
      };
    }
    case "SET_IMAGE_URL": {
      return { ...state, imageUrl: action.payload };
    }
    case "SET_GYM_INFO": {
      return { ...state, gymInfo: action.payload };
    }
    case "SET_USER_TYPE": {
      return { ...state, userType: action.payload };
    }
    case "SET_STAFF_INFO": {
      return {
        ...state,
        staffUser: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
