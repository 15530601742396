import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { ServiceWrapper } from "../services/serviceWrapper";
import { API_HOSTNAME } from "../shared/constants";
import {
  ICustomWorkoutPlanResponse,
  ICustomWorkoutSet,
  IGymCustomer,
} from "../interfaces";

export const CustomerWorkouts = () => {
  const service = new ServiceWrapper();
  const [searchParams, setSearchParams] = useSearchParams();
  const [gymCustomer, setGymcustomer] = React.useState<IGymCustomer | null>(
    null,
  );
  const [workoutSets, setWorkoutSets] = React.useState<ICustomWorkoutSet[]>([]);

  React.useEffect(() => {
    if (searchParams.get("customer_id")) {
      service
        .callPost(`${API_HOSTNAME}/gym_customer/get-customer-workout-plan`, {
          gym_customer_id: searchParams.get("customer_id"),
        })
        .then((response) => {
          if (response.data.customerWorkout) {
            setWorkoutSets(response.data.customerWorkout.workout_sets);
          }
          setGymcustomer(response.data.customer);
        });
    }
  }, [searchParams]);

  const getPlanForTheDay = (day: string) => {
    const dayWorkout = workoutSets.find(
      (w) => w.day.toLowerCase() === day.toLowerCase(),
    );
    console.log(day + ">", dayWorkout);
    return dayWorkout;
  };

  return (
    <Container style={{ backgroundColor: "#fff", padding: "10px 0" }}>
      <img
        src={
          "https://ampious.in/wp-content/uploads/2024/08/cropped-Ampious-transparent-logo-150x150.png"
        }
      />
      <Grid container>
        <Grid item mt={3} paddingLeft={5}>
          <Typography variant="h3" textTransform={"capitalize"}>
            Hi, {gymCustomer?.first_name} {gymCustomer?.last_name}
          </Typography>
          <Typography variant="h5">Here is your Workout Plan</Typography>
        </Grid>
      </Grid>
      <Grid container mt={3} paddingLeft={5}>
        <Grid item>
          <h2>Monday</h2>
          <div
            style={{ marginTop: 5, paddingLeft: 20 }}
            dangerouslySetInnerHTML={{
              __html: getPlanForTheDay("monday")
                ? getPlanForTheDay("monday")!.exercises
                : "<p>-Rest-</p>",
            }}
          ></div>
        </Grid>
      </Grid>
      <Grid container mt={3} paddingLeft={5}>
        <Grid item>
          <h2>Tuesday</h2>
          <div
            style={{ marginTop: 5, paddingLeft: 20 }}
            dangerouslySetInnerHTML={{
              __html: getPlanForTheDay("Tuesday")
                ? getPlanForTheDay("Tuesday")!.exercises
                : "<p>-Rest-</p>",
            }}
          ></div>
        </Grid>
      </Grid>
      <Grid container mt={3} paddingLeft={5}>
        <Grid item>
          <h2>Wednesday</h2>
          <div
            style={{ marginTop: 5, paddingLeft: 20 }}
            dangerouslySetInnerHTML={{
              __html: getPlanForTheDay("Wednesday")
                ? getPlanForTheDay("Wednesday")!.exercises
                : "<p>-Rest-</p>",
            }}
          ></div>
        </Grid>
      </Grid>
      <Grid container mt={3} paddingLeft={5}>
        <Grid item>
          <h2>Thrusday</h2>
          <div
            style={{ marginTop: 5, paddingLeft: 20 }}
            dangerouslySetInnerHTML={{
              __html: getPlanForTheDay("Thrusday")
                ? getPlanForTheDay("Thrusday")!.exercises
                : "<p>-Rest-</p>",
            }}
          ></div>
        </Grid>
      </Grid>
      <Grid container mt={3} paddingLeft={5}>
        <Grid item>
          <h2>Friday</h2>
          <div
            style={{ marginTop: 5, paddingLeft: 20 }}
            dangerouslySetInnerHTML={{
              __html: getPlanForTheDay("Friday")
                ? getPlanForTheDay("Friday")!.exercises
                : "<p>-Rest-</p>",
            }}
          ></div>
        </Grid>
      </Grid>
      <Grid container mt={3} paddingLeft={5}>
        <Grid item>
          <h2>Saturday</h2>
          <div
            style={{ marginTop: 5, paddingLeft: 20 }}
            dangerouslySetInnerHTML={{
              __html: getPlanForTheDay("Saturday")
                ? getPlanForTheDay("Saturday")!.exercises
                : "<p>-Rest-</p>",
            }}
          ></div>
        </Grid>
      </Grid>
      <Grid container mt={3} paddingLeft={5}>
        <Grid item>
          <h2>Sunday</h2>
          <div
            style={{ marginTop: 5, paddingLeft: 20 }}
            dangerouslySetInnerHTML={{
              __html: getPlanForTheDay("Sunday")
                ? getPlanForTheDay("Sunday")!.exercises
                : "<p>-Rest-</p>",
            }}
          ></div>
        </Grid>
      </Grid>
    </Container>
  );
};
