import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { API_HOSTNAME, THEME_ORANGE } from "../shared/constants";
import {
  setGymInfoAction,
  setMemberShipInfoAction,
  setUserAction,
  setUserTokenAction,
  setUserTypeAction,
} from "../store";
import Swal from "sweetalert2";
import {
  Button,
  Container,
  Divider,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../assets/photo/image-removebg-preview.png";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../store/store";

export const Login = () => {
  const title = "Login";
  const navigate = useNavigate();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const token = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).token;

  const userType = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).userType;

  React.useEffect(() => {
    console.log("userType", userType);
    console.log("user", user);
    console.log("token", token);

    if (user && token) {
      window.location.href = "/gym-owner/dashboard";
    }
  }, [user, userType, token]);

  const [form, setForm] = React.useState({
    email: "",
    password: "",
  });

  React.useEffect(() => {
    document.title = `Ampious : ${title}`;
    document.addEventListener("keyup", (event) => {
      if (event.key === "Enter") {
        loginHandle();
      }
    });
  }, []);

  const loginHandle = async () => {
    if (!form.email || !form.password) {
      Swal.fire(
        "Login Error",
        "Please Enter correct Email and password",
        "error",
      );
    }
    console.log(API_HOSTNAME);
    axios
      .post(`${API_HOSTNAME}/gym-owner/login`, {
        email: form.email,
        password: form.password,
      })
      .then((response: AxiosResponse) => {
        localStorage.setItem(
          "gym_info",
          JSON.stringify(response.data.gym_info),
        );
        localStorage.setItem(
          "member_ship_details",
          JSON.stringify(response.data.memberShipDetail),
        );
        localStorage.setItem("token", response.headers["authorization"]);
        localStorage.setItem("user-type", "gym-owner");
        localStorage.setItem("user", JSON.stringify(response.data.gym_owner));

        setMemberShipInfoAction(response.data.memberShipDetail);
        setGymInfoAction(response.data.gym_info);
        setUserTypeAction("gym-owner");
        setUserAction(response.data.gym_owner);
        setUserTokenAction(response.headers["authorization"]);
        console.log("sss", response.headers["authorization"]);
      })
      .catch((error) => {
        Swal.fire(
          "Login Error",
          "Please Enter correct Email and password",
          "error",
        );
      });
  };

  const setFormValues = (field: string, value: string) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  return (
    <>
      <div className="h-[90vh]">
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div className="col-md-5">
            <div className="card">
              <div className="card-header text-center">
                <img
                  src={logo}
                  alt="ampious-logo"
                  width={"25%"}
                  style={{ alignSelf: "center" }}
                />
              </div>
              <div className="card-header text-center">
                <Typography variant="h4">Welcome back</Typography>
                <Typography variant="h4"> Please Login</Typography>
              </div>
              <div className="card-body">
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    type="text"
                    value={form.email}
                    placeholder="Enter Your User Name"
                    className="form-control"
                    onChange={(event) => {
                      setFormValues("email", event.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Password"
                    type="password"
                    value={form.password}
                    placeholder="Enter Your Password"
                    className="form-control my-3"
                    onChange={(event) => {
                      setFormValues("password", event.target.value);
                    }}
                  />
                </FormControl>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    onClick={loginHandle}
                    sx={{ width: "12rem" }}
                  >
                    Login
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: THEME_ORANGE }}
                    onClick={() => navigate("/public/register")}
                    sx={{ width: "12rem" }}
                  >
                    Register
                  </Button>
                </div>
                <Divider style={{ margin: "10px 0px" }} />
                <div className="row mt-2">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => navigate("/public/forgot-password")}
                    >
                      Forgot Password ?
                    </Button>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: THEME_ORANGE }}
                      onClick={() => navigate("/public/gym-staff/login")}
                      sx={{ width: "12rem" }}
                    >
                      Staff Login
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
