import { Button } from "@mui/material";

interface ICustomButton {
  type: "success" | "error";
  onClick: () => void;
  text: string;
  fullWidth: boolean;
}
export const CustomButton = (props: ICustomButton) => {
  return (
    <Button
      color="inherit"
      fullWidth={props.fullWidth}
      variant="contained"
      style={{
        backgroundColor: props.type === "success" ? "#FF9F43" : "#00CFE8",
        color: "#fff",
      }}
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  );
};
