import * as React from "react";
import axios, { AxiosResponse } from "axios";

import {
  Button,
  Grid,
  MenuItem,
  FormControl,
  TextField,
  Divider,
  Alert,
  InputLabel,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
} from "@mui/material";
import { API_HOSTNAME, THEME_ORANGE } from "../../../shared/constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  IGymCustomerResponse,
  IGymMemberShipPlanResponse,
} from "../../../interfaces";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import moment from "moment-timezone";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import Swal from "sweetalert2";

export const CustomerDetail = () => {
  const title = " Customer Details";
  const navigate = useNavigate();
  const params = useParams();
  const service = new ServiceWrapper();

  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;
  const [plans, setPlans] = React.useState<IGymMemberShipPlanResponse[]>([]);
  const [
    shouldShowActivateMembershipModal,
    setShouldShowActivateMembershipModal,
  ] = React.useState<boolean>(false);
  const [shouldConfirmDeletion, setShouldConfirmDeletion] =
    React.useState<boolean>(false);
  const [customerDetails, setCustomerDetails] =
    React.useState<IGymCustomerResponse | null>(null);

  const getCustomerDetails = () => {
    axios
      .get(
        `${API_HOSTNAME}/gym_customer/get-details/${params.customer_id}/${gym!._id}`,
      )
      .then((response: AxiosResponse<IGymCustomerResponse>) => {
        setCustomerDetails(response.data);
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    document.title = `Ampious : ${title}`;
  }, []);

  React.useEffect(() => {
    if (gym && gym!._id) {
      getMyPlans();
      getCustomerDetails();
    }
  }, [gym]);

  const getMyPlans = () => {
    service
      .callGet(
        `${API_HOSTNAME}/gym_membership_plans/my_plans?gym_id=${gym!._id}`,
      )
      .then((response) => {
        console.log("ok");
        setPlans(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  React.useEffect(() => {
    console.log("hhh");
    if (customerDetails?.membership_start_date) {
      const planId = customerDetails?.plan_id;
      if (planId) {
        const planTenure = plans.find((p) => p._id === planId)?.tenure;
        console.log("planTenure", planTenure);
        if (planTenure) {
          const startDate = moment(
            customerDetails.membership_start_date,
          ).format("L");
          const endDate = moment(customerDetails.membership_start_date).add(
            planTenure,
            "months",
          );
          setCustomerDetails({
            ...customerDetails,
            membership_start_date: startDate,
            membership_end_date: endDate.format("L"),
          });
        }
      }
    }
  }, [customerDetails?.membership_start_date]);

  const updateCustomerDetails = () => {
    axios
      .put(
        `${API_HOSTNAME}/gym_customer/update-customer-details/${customerDetails!._id}`,
        {
          ...customerDetails,
          gym_id: gym!._id,
          membership_status:
            customerDetails?.membership_start_date &&
            customerDetails.membership_end_date
              ? "ACTIVE"
              : "INACTIVE",
        },
      )
      .then((response) => {
        getCustomerDetails();
        setShouldShowActivateMembershipModal(false);
      })
      .catch((error) => {});
  };

  const deleteCustomer = () => {
    Swal.fire({
      icon: "warning",
      title: `Are you sure to delete customer ${customerDetails?.first_name} ${customerDetails?.last_name} ?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((response) => {
      console.log("response", response);
      if (response.isConfirmed) {
        deleteCustomerAfterConfirmation();
      }
    });
  };

  const deleteCustomerAfterConfirmation = () => {
    service
      .callDelete(
        `${API_HOSTNAME}/gym_customer/delete-customer/${customerDetails!._id}`,
        {},
      )
      .then((response) => {
        navigate("/gym-owner/gym-customers");
      });
  };

  return (
    <>
      {/* main view starts */}
      {customerDetails ? (
        <>
          <Dialog open={shouldShowActivateMembershipModal}>
            <DialogTitle>
              Assign membership Plan to {customerDetails?.first_name}{" "}
              {customerDetails?.last_name}
            </DialogTitle>
            <DialogContent>
              <Grid container gap={2} direction="column">
                <Grid item>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Select Plan</InputLabel>
                    <Select
                      onChange={(e) => {
                        setCustomerDetails({
                          ...customerDetails!,
                          plan_id: e.target.value as string,
                        });
                      }}
                    >
                      {plans.map((plan) => {
                        return (
                          <MenuItem value={plan._id}>
                            {plan.plan_name}({plan.tenure} months)
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Button
                      variant="text"
                      style={{ alignSelf: "flex-end" }}
                      onClick={() => {
                        navigate("/gym-owner/gym-membership-plan");
                      }}
                    >
                      Add New Plan
                    </Button>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        onChange={(value) => {
                          setCustomerDetails({
                            ...customerDetails!,
                            membership_start_date: value?.toString()!,
                          });
                        }}
                      />
                    </LocalizationProvider>
                    <Typography component="h6" textAlign={"right"}>
                      Plan will end on{" "}
                      {moment(customerDetails?.membership_end_date).format("L")}
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ display: "flex" }}>
              <Button
                variant="contained"
                onClick={() => setShouldShowActivateMembershipModal(false)}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={updateCustomerDetails}>
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <div className="h-screen">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex">
                <h4>{title} </h4>
              </div>
              <Divider />

              <Grid
                container
                marginLeft={2}
                margin={"10px 7px"}
                padding={"0 10px"}
                direction="row-reverse"
                flexWrap={"nowrap"}
                gap={2}
              >
                <Grid
                  container
                  item
                  direction={"column"}
                  justifyContent={"flex-start"}
                  gap={2}
                  flex={0.8}
                  mt={2}
                >
                  <Grid item>
                    <h5>
                      <b>Customer Details</b>
                    </h5>
                  </Grid>
                  <Grid item>
                    <h5>
                      <b>Name:</b> {customerDetails?.first_name}{" "}
                      {customerDetails?.last_name}
                    </h5>
                  </Grid>
                  <Grid item>
                    <h5>
                      <b>Email: </b>
                      {customerDetails?.email}
                    </h5>
                  </Grid>
                  <Grid item>
                    <h5>
                      <b>DOB: </b>
                      {customerDetails?.dob || "NA"}
                    </h5>
                  </Grid>
                  <Grid item>
                    <h5>
                      <b>Gender: </b>
                      {customerDetails?.gender || "NA"}
                    </h5>
                  </Grid>
                  <Grid item>
                    <h5>
                      <b>Phone Number: </b>
                      {customerDetails?.phone_number || "NA"}
                    </h5>
                  </Grid>
                  <Grid item>
                    <h5>
                      <b>Other Membership Detail: </b>
                      {customerDetails?.other_membership_detail || "NA"}
                    </h5>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item flex={0.2} mt={2}>
                  <img
                    src={
                      "https://res.cloudinary.com/dlgbobpmu/image/upload/v1724400754/ampious/wpsmlco8cxuodcddh4yo.png"
                    }
                    width={"100%"}
                  />
                </Grid>
              </Grid>
              <Divider />

              <Grid
                container
                marginLeft={2}
                margin={"10px 7px"}
                padding={"10px 10px"}
                direction="column"
                flexWrap={"nowrap"}
                gap={2}
              >
                <Grid item>
                  <h5>
                    <b>Membership Details</b>
                  </h5>
                </Grid>
                <Grid item>
                  <p style={{ marginTop: 2 }}>
                    {customerDetails?.membership_status.toUpperCase() ===
                      "INACTIVE" ||
                    customerDetails?.membership_status.toUpperCase() === "" ? (
                      <Alert severity="warning" style={{ flex: 1 }}>
                        <Grid container justifyContent={"space-between"}>
                          <Grid item>
                            {customerDetails?.first_name} has no active
                            membership
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={() => {
                                setShouldShowActivateMembershipModal(true);
                              }}
                              variant="contained"
                              style={{
                                backgroundColor: THEME_ORANGE,
                                height: 30,
                              }}
                            >
                              Activate membership
                            </Button>
                          </Grid>
                        </Grid>
                      </Alert>
                    ) : (
                      <Alert severity="success" style={{ flex: 1 }}>
                        {customerDetails?.membership_status.toUpperCase()}
                      </Alert>
                    )}
                  </p>
                </Grid>
                <Grid item>
                  <p style={{ marginTop: 10 }}>
                    <b>Membership start Date:</b>{" "}
                    {moment(customerDetails?.membership_start_date).format("L")}
                  </p>
                </Grid>
                <Grid item>
                  <p style={{ marginTop: 2 }}>
                    <b>Membership end Date:</b>{" "}
                    {moment(customerDetails?.membership_end_date).format("L")}
                  </p>
                </Grid>
              </Grid>
              <Divider />

              <Grid
                container
                padding={"10px 10px"}
                direction="column"
                flexWrap={"nowrap"}
                gap={2}
              >
                <Grid item mt={2}>
                  <h5>
                    <b>Update Customer Details</b>
                  </h5>
                </Grid>

                <Grid item mt={2}>
                  <FormControl fullWidth variant="outlined">
                    {/* <InputLabel htmlFor="my-input">First Name</InputLabel> */}
                    <TextField
                      placeholder="First Name"
                      fullWidth
                      id="my-input"
                      value={customerDetails?.first_name}
                      onChange={(event) => {
                        setCustomerDetails({
                          ...customerDetails,
                          first_name: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item mt={2}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      placeholder="Last Name"
                      fullWidth
                      value={customerDetails?.last_name}
                      onChange={(event) => {
                        setCustomerDetails({
                          ...customerDetails,
                          last_name: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item mt={2}>
                  <FormControl fullWidth variant="outlined">
                    {/* <InputLabel htmlFor="my-input">First Name</InputLabel> */}
                    <TextField
                      placeholder="Email"
                      fullWidth
                      value={customerDetails?.email}
                      onChange={(event) => {
                        setCustomerDetails({
                          ...customerDetails,
                          email: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Gender</InputLabel>
                    <Select
                      value={customerDetails.gender}
                      onChange={(event) => {
                        setCustomerDetails({
                          ...customerDetails,
                          gender: event.target.value as string,
                        });
                      }}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item mt={2}>
                  <FormControl fullWidth variant="outlined">
                    {/* <InputLabel htmlFor="my-input">First Name</InputLabel> */}
                    <TextField
                      placeholder="Phone Number"
                      fullWidth
                      value={customerDetails?.phone_number}
                      onChange={(event) => {
                        setCustomerDetails({
                          ...customerDetails,
                          phone_number: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item container gap={2}>
                  <Button
                    style={{ backgroundColor: THEME_ORANGE, color: "#fff" }}
                    fullWidth
                    onClick={updateCustomerDetails}
                  >
                    Update Details
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={deleteCustomer}
                  >
                    Delete this customer
                  </Button>
                </Grid>

                {/* <Grid item>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker value={dayjs(customerDetails.dob).toDate()} label={'"month" and "year"'} views={["month", "year"]} />
						</LocalizationProvider>
					</Grid> */}
              </Grid>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
