import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  Input,
  InputBase,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";

import React from "react";
import { ServiceWrapper } from "../services/serviceWrapper";
import { API_HOSTNAME } from "../shared/constants";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../store/store";
import Swal from "sweetalert2";

export const ContactUs = () => {
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;

  const service = new ServiceWrapper();
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    file: null,
    message: "",
  });

  React.useEffect(() => {
    setFormData({
      ...formData,
      name: user ? user?.fname + " " + user?.lname : "",
      email: user?.email || "",
      file: null,
      message: "",
    });
  }, [user]);

  const submitQuery = () => {
    service
      .callPost(`${API_HOSTNAME}/admin/submit-query`, formData)
      .then((response) => {
        Swal.fire(
          "Success",
          "We have recieved your query, please check your mail for further details",
          "success",
        );
      })
      .catch((error) => {});
  };

  return (
    <Container
      style={{
        backgroundColor: "#fff",
        padding: "10px 10px",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <img
        src={
          "https://ampious.in/wp-content/uploads/2024/08/cropped-Ampious-transparent-logo-150x150.png"
        }
      />
      <Typography variant="h3">Contact Us</Typography>

      <Grid container>
        <Grid item mt={3} padding={"0 5px"} container direction={"row"}>
          <Grid item>
            <div>
              <p style={{ margin: 0, padding: 0 }}>
                Welcome to Ampious, where innovation meets dedication.Our
                mission is to provide more power to small and medium sized gyms,
                driving us to continually push boundaries and set new standards.
                Our passionate team of experts brings together diverse skills
                and backgrounds, united by a shared vision of excellence and
                customer satisfaction. Join us on our journey as we strive to
                make a difference, one service at a time.
              </p>
              <Divider />
            </div>
          </Grid>
          <Grid item container direction="column">
            <Grid item mt={5} mb={5}>
              <h6>
                <b>
                  <u>Contact Us on</u>
                </b>
              </h6>
              <p style={{ margin: "5px 0 0 0", padding: 0 }}>
                Office Address: Indira nagar, Near Munshipulia, Lucknow
              </p>
              <p style={{ margin: 0, padding: 0 }}>Phone: +91 9026631323</p>
            </Grid>
            <Divider />
            <Grid item mt={5} mb={5}>
              <h6>
                <b>
                  <u>React Us</u>
                </b>
              </h6>
              <p>
                {" "}
                Please provide the details below, We will get back to you in
                24-48 hours
              </p>
              <FormControl fullWidth>
                <TextField
                  label={"Your Name"}
                  value={formData.name}
                  required={true}
                  sx={{ marginTop: "1rem" }}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
                <TextField
                  label="Your Email"
                  value={formData.email}
                  required={true}
                  sx={{ marginTop: "1rem" }}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
                <TextareaAutosize
                  minRows={2}
                  placeholder="Your Message"
                  className="rounded-md mt-2 p-2"
                  value={formData.message}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      message: event.target.value,
                    });
                  }}
                />
                <Button
                  variant="contained"
                  sx={{ marginTop: "1rem" }}
                  onClick={submitQuery}
                >
                  Submit
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
