import { Outlet, Route, Routes } from "react-router-dom";
import {
  AddClasses,
  ChangePassword,
  Communication,
  CustomerDetail,
  CustomerVitals,
  CustomWorkoutPlan,
  Dashboard,
  EventsPage,
  GymCustomer,
  GymInfo,
  GymMembershipPlan,
  MemberShipDetail,
  Profile,
  Staff,
  StaffAttendance,
  StaffShift,
  Visitor,
  WorkoutManager,
  WorkoutPlanEditor,
} from "./innerPages";
import {
  Login,
  Register,
  ForgotPassword,
  ContactUs,
  PrivacyPolicy,
  TermsAndConditions,
  RefundsPolicy,
  AboutUs,
} from "../publicPages";
import { PageNotFound } from "../publicPages/pageNotFound";
import { CustomerWorkouts } from "../publicPages/customer-workout";
import {
  GymStaffForgotPassword,
  StaffLogin,
  StaffRegister,
} from "../publicPages/staffs";
import { StaffDashboard, GymStaffLayouts } from "./innerPages/staff";
import GymOwnerLayouts from "./innerPages/gym-owner/layouts";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../store/store";
import { ErrorPage } from "./errorPage";
import { AdminLogin } from "../publicPages/admin";
import { AdminDashboard } from "./innerPages/admin";
import AdminLayouts from "./innerPages/admin/layouts";

export const AppRoutes = () => {
  const userType = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).userType;

  return (
    <>
      {userType === "gym-owner" && <GymOwnerLayouts />}
      {userType === "gym-staff" && <GymStaffLayouts />}
      {userType === "admin" && <AdminLayouts />}
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/public">
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" Component={ForgotPassword} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="refunds-policy" element={<RefundsPolicy />} />
          <Route path="view-customer-workout" element={<CustomerWorkouts />} />
          <Route path="error-occured" element={<ErrorPage />} />

          <Route path="gym-staff">
            <Route path="login" element={<StaffLogin />} />
            <Route path="register" element={<StaffRegister />} />
            <Route
              path="forgot-password"
              element={<GymStaffForgotPassword />}
            />
          </Route>
          <Route path="admin">
            <Route path="login" element={<AdminLogin />} />
          </Route>
        </Route>

        <Route path="/admin">
          <Route path="dashboard" element={<AdminDashboard />} />
        </Route>

        <Route path="/gym-staff">
          <Route path="dashboard" element={<StaffDashboard />} />
        </Route>

        <Route path="/gym-owner">
          <Route path="dashboard" index element={<Dashboard />} />
          <Route path="profileSetting" element={<Profile />} />
          <Route path="gym-info" element={<GymInfo />} />
          <Route path="gym-customers" element={<GymCustomer />} />
          <Route path="gym-membership-plan" element={<GymMembershipPlan />} />
          <Route path="workout-manager" element={<WorkoutManager />} />
          <Route
            path="workout-plan-editor/:plan_id"
            element={<WorkoutPlanEditor />}
          />
          <Route
            path="customer-detail/:customer_id"
            element={<CustomerDetail />}
          />
          <Route
            path="customer-vitals/:customer_id"
            element={<CustomerVitals />}
          />
          <Route path="staff" element={<Staff />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="staff-shift/:customer_id" element={<StaffShift />} />
          <Route path="add-classes" element={<AddClasses />} />
          <Route path="visitor-management" element={<Visitor />} />
          <Route path="communication" element={<Communication />} />
          <Route
            path="custom-workout-plan/:plan_id"
            element={<CustomWorkoutPlan />}
          />
          <Route path="membership-details" element={<MemberShipDetail />} />
          <Route path="add-edit-events" element={<EventsPage />} />
          <Route path="staff-attendence" element={<StaffAttendance />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Outlet />
    </>
  );
};
