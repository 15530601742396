import { useState } from "react";
import {
  TextField,
  Button,
  Alert,
  Box,
  InputAdornment,
  IconButton,
  Container,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { API_HOSTNAME } from "../../../shared/constants";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import Swal from "sweetalert2";
import logo from "../../../assets/photo/image-removebg-preview.png";

export const ChangePassword = () => {
  const title = "Change Password";
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [err, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  document.title = `Ampious : ${title}`;
  const callService = new ServiceWrapper();
  const email = useSelector((state: IGlobalStoreState) => state.userReducer)
    .user?.email;
  const handleClickShowPassword = (field: "current" | "new" | "confirm") => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setSuccess(null);
      setError("Passwords do not match");
    } else if (
      currentPassword === "" ||
      newPassword === "" ||
      confirmPassword === ""
    ) {
      setError("All fields are required");
      setSuccess(null);
    } else {
      const data = {
        email: email,
        old_password: currentPassword,
        new_password: newPassword,
      };
      try {
        const response = await callService.callPost(
          `${API_HOSTNAME}/gym-owner/change-password`,
          data,
        );

        console.log(response.data);
        if (response.status === 200) {
          setSuccess(response.data.message);
          setError(null);
          Swal.fire("Success", response.data.message, "success");
        }
      } catch (error: any) {
        console.log(error.response.data.message);
        setError(error.response.data.message);
        setSuccess(null);
        Swal.fire("Error", error.response.data.message, "error");
      }
    }
  };
  return (
    <Container
      sx={{
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="card" style={{ padding: "10px", maxWidth: "400px" }}>
        {" "}
        <div className="card-header text-center">
          <img
            src={logo}
            alt="ampious-logo"
            width={"25%"}
            style={{ alignSelf: "center" }}
          />
        </div>
        <Box>
          <TextField
            label="Current Password"
            type={showPassword.current ? "text" : "password"}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("current")}
                  >
                    {showPassword.current ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="New Password"
            type={showPassword.new ? "text" : "password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClickShowPassword("new")}>
                    {showPassword.new ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            type={showPassword.confirm ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("confirm")}
                  >
                    {showPassword.confirm ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {err && <Alert severity="error">{err}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <Button onClick={handleSubmit} variant="contained" fullWidth>
            Change Password
          </Button>
        </Box>
      </div>
    </Container>
  );
};
