import { Grid } from "@mui/material";

export const EventsPage = () => {
  const title = "Events";

  return (
    <div className="h-screen" style={{ backgroundColor: "#fff" }}>
      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <div className="card-header d-flex justify-content-between border-bottom pb-1">
          <Grid
            container
            padding={"0.5rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>{title}</Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
