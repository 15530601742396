import React from "react";
import axios, { AxiosResponse } from "axios";
import { API_HOSTNAME, blue, grey } from "../../../shared/constants";
import { ICustomerDetail } from "../../../interfaces/ICustomerDetail";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  styled,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { CustomDialog } from "../../../shared";
import {
  ICustomerVital,
  ICustomerVitalResponse,
} from "../../../interfaces/ICustomerVital";
import { NumberInput } from "../../../shared/UIComponents/NumberInput";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import moment from "moment-timezone";
import Graph from "../../../components/Graph";

export const CustomerVitals = () => {
  const title = "Customer Vitals";
  const params = useParams();
  const callService = new ServiceWrapper();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [customerVitals, setCustomerVitals] = React.useState<
    ICustomerVitalResponse[]
  >([]);
  const [graphData, setGraphData] = React.useState<string[]>([]);

  const [customerVital, setCustomerVital] = React.useState<ICustomerVital>({
    weight: "",
    height: "",
    customer_id: "",
    waist: "",
    hips: "",
    bmi: "",
    biceps: "",
    triceps: "",
    medical_info: "",
    isDiabetic: false,
    isHypothyroid: false,
    isHyperthyroid: false,
  });

  React.useEffect(() => {
    document.title = `Ampious : ${title}`;
    getCustomerDetails();
  }, []);

  const columns: GridColDef<ICustomerVitalResponse>[] = [
    {
      field: "created_on",
      headerName: "Date",
      width: 150,
      editable: true,
      renderCell(params) {
        return moment(params.row.created_on).format("DD/MM/YYYY");
      },
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 150,
      editable: true,
    },
    {
      field: "height",
      headerName: "Height ",
      width: 150,
      editable: true,
    },
    {
      field: "waist",
      headerName: "Waist",
      type: "number",
      width: 150,
      editable: true,
    },
    {
      field: "hips",
      headerName: "Hip",
      type: "number",
      width: 150,
      editable: true,
    },
    {
      field: "isDiabetic",
      headerName: "isDiabetic",
      type: "number",
      width: 150,
      editable: true,
      renderCell(params) {
        return <p>{params.row.isDiabetic ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "isHypothyroid",
      headerName: "isHypothyroid",
      type: "number",
      width: 150,
      editable: true,
      renderCell(params) {
        return <p>{params.row.isHypothyroid ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "isHyperthyroid",
      headerName: "isHyperthyroid",
      type: "number",
      width: 150,
      editable: true,
      renderCell(params) {
        return <p>{params.row.isHyperthyroid ? "Yes" : "No"}</p>;
      },
    },
  ];

  const [customerDetails, setCustomerDetails] = React.useState<ICustomerDetail>(
    {
      customerInfo: null,
      customerPlanInfo: null,
    },
  );

  React.useEffect(() => {
    customerDetails.customerInfo && getCustomerVitals();
  }, [customerDetails]);

  const getCustomerDetails = () => {
    axios
      .get(`${API_HOSTNAME}/gym_customer/get-details/${params.customer_id}`)
      .then((response: AxiosResponse<{ data: ICustomerDetail }>) => {
        setCustomerDetails(response.data.data);
        setCustomerVital({
          ...customerVital,
          customer_id: response.data.data.customerInfo?._id!,
        });
      })
      .catch((error) => {});
  };

  const getCustomerVitals = () => {
    callService
      .callGet(
        `${API_HOSTNAME}/gym_customer/get-customer-vitals/${customerDetails.customerInfo?._id}`,
      )
      .then((response) => {
        setCustomerVitals(response.data.data);
        console.log(customerVitals);
        const weightData = response.data.data.map(
          (item: ICustomerVitalResponse) => Number(item.weight),
        );
        console.log("This weight data", weightData);
        setGraphData(weightData);
      })
      .catch((error) => {});
  };

  const saveVital = () => {
    setIsModalOpen(false);
    callService
      .callPost(
        `${API_HOSTNAME}/gym_customer/add-customer-vital`,
        customerVital,
      )
      .then((response) => {
        console.log("response", response);
        getCustomerVitals();
      })
      .catch((error) => {});
  };

  console.log("This is graph Data", graphData);

  return (
    <div className="container-fluid">
      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <div className="card-header d-flex justify-content-between border-bottom pb-1">
          <div className="capitalise">{title}</div>
        </div>
        <div className="card-body">
          <Grid
            container
            direction={"row"}
            justifyContent={"space-between"}
            flexWrap={"nowrap"}
          >
            <Grid item container direction={"column"}>
              <Grid item>
                <p className="capitalise">
                  <b>Name:</b> {customerDetails.customerInfo?.first_name}{" "}
                  {customerDetails.customerInfo?.last_name}
                </p>
              </Grid>
              <Grid>
                <p className="capitalise">
                  <b>Age:</b> {customerDetails.customerInfo?.age || "NA"}
                </p>
              </Grid>
              <Grid>
                <p className="capitalise">
                  <b>DOB:</b> {customerDetails.customerInfo?.dob || "NA"}
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent={"flex-end"}
              alignContent={"center"}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  Add Vitals
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <DataGrid
          rows={customerVitals}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          getRowId={(row: ICustomerVitalResponse) => row._id}
        />

        <div className="w-[96] h-96">
          <Graph data={graphData} />
        </div>
      </div>

      <CustomDialog
        fullWidth
        maxWidth="lg"
        isModalOpen={isModalOpen}
        onSaveClick={function (): void {
          console.log("form", customerVital);
          saveVital();
        }}
        onCancelClick={function (): void {
          setIsModalOpen(false);
        }}
        headerText={"Add Vitals"}
      >
        <Grid container direction="row">
          <Grid item flex={0.5}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <NumberInput
                placeholder="Weight"
                value={customerVital.weight}
                onChange={(text: string) => {
                  setCustomerVital({
                    ...customerVital,
                    weight: text,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item flex={0.5} marginLeft={5}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <NumberInput
                placeholder="Height"
                value={customerVital.height}
                onChange={(text: string) => {
                  setCustomerVital({
                    ...customerVital,
                    height: text,
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container direction={"row"}>
          <Grid item flex={0.5}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <NumberInput
                placeholder="Waist"
                value={customerVital.waist}
                onChange={(text: string) => {
                  setCustomerVital({
                    ...customerVital,
                    waist: text,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item flex={0.5} marginLeft={5}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <NumberInput
                placeholder="Hips"
                value={customerVital.hips}
                onChange={(text: string) => {
                  setCustomerVital({
                    ...customerVital,
                    hips: text,
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container direction={"row"}>
          <Grid item flex={0.5}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <NumberInput
                placeholder="Biceps"
                value={customerVital.biceps}
                onChange={(text: string) => {
                  setCustomerVital({
                    ...customerVital,
                    biceps: text,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item flex={0.5} marginLeft={5}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <NumberInput
                placeholder="Triceps"
                value={customerVital.triceps}
                onChange={(text: string) => {
                  setCustomerVital({
                    ...customerVital,
                    triceps: text,
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container direction={"row"}>
          <Grid item flex={0.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customerVital.isDiabetic}
                  onChange={(e) => {
                    setCustomerVital({
                      ...customerVital,
                      isDiabetic: e.target.checked,
                    });
                  }}
                  title="is Diabetic"
                />
              }
              label="Diabetic?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={customerVital.isHyperthyroid}
                  onChange={(e) => {
                    setCustomerVital({
                      ...customerVital,
                      isHyperthyroid: e.target.checked,
                    });
                  }}
                  title="is Hyperthyroid"
                />
              }
              label="Hyperthyroid?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={customerVital.isHypothyroid}
                  onChange={(e) => {
                    setCustomerVital({
                      ...customerVital,
                      isHypothyroid: e.target.checked,
                    });
                  }}
                  title="isHypothyroid"
                />
              }
              label="Hypothyroid?"
            />
          </Grid>
        </Grid>

        <Grid item flex={0.5}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextareaAutosize
              placeholder="Any other medical Details"
              value={customerVital.medical_info}
              onChange={(event) => {
                setCustomerVital({
                  ...customerVital,
                  medical_info: event.target.value,
                });
              }}
            />
          </FormControl>
        </Grid>
      </CustomDialog>
    </div>
  );
};

const TextareaAutosize = styled("textarea")(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);
