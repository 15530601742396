import * as React from "react";
import "./App.css";
import { ErrorBoundary } from "react-error-boundary";
import {
  setGymInfoAction,
  setGymStaffAction,
  setMemberShipInfoAction,
  setUserAction,
  setUserTokenAction,
  setUserTypeAction,
} from "./store";
import { AppRoutes } from "./pages/appRoutes";
import axios from "axios";
import { THEME_ORANGE } from "./shared/constants";
import { CircularProgress, Container, Grid, Modal } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { ErrorPage } from "./pages/errorPage";

function App() {
  const navigate = useNavigate();

  // const user = useSelector((state: IGlobalStoreState) => state.userReducer).user;
  const [shouldShowLoader, setShouldShowLoader] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const checkStorage = async () => {
      const user = await localStorage.getItem("user");
      const staff = await localStorage.getItem("gym-staff");
      const token = await localStorage.getItem("token");
      const gymInfo = await localStorage.getItem("gym_info");
      const memberShipDetails = await localStorage.getItem(
        "member_ship_details",
      );
      const userType = await localStorage.getItem("user-type");

      if (token && userType) {
        userType && setUserTypeAction(userType);
        axios.defaults.headers["authorization"] = `Bearer ${token}`;

        if (userType === "gym-staff" && staff) {
          gymInfo && setGymInfoAction(JSON.parse(gymInfo));
          setGymStaffAction(JSON.parse(staff));
          setUserTokenAction(token);
        } else if (userType === "gym-owner" && user) {
          try {
            setUserAction(JSON.parse(user));
            gymInfo && setGymInfoAction(JSON.parse(gymInfo));
            memberShipDetails &&
              memberShipDetails !== undefined &&
              setMemberShipInfoAction(JSON.parse(memberShipDetails));
            setUserTokenAction(token);
          } catch (e) {}
        } else if (userType === "admin" && user) {
          setUserAction(JSON.parse(user));
          setUserTokenAction(token);
        } else {
          // navigate("/public/login");
        }
      } else {
        // navigate("/public/login");
      }
    };

    axios.interceptors.request.use(
      (config) => {
        setShouldShowLoader(true);
        return config;
      },
      (error) => {
        setShouldShowLoader(false);
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      (config) => {
        setShouldShowLoader(false);
        return config;
      },
      (error) => {
        console.log("Error", error);
        setShouldShowLoader(false);
        return Promise.reject(error);
      },
    );

    checkStorage();
  }, []);

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Modal open={shouldShowLoader}>
        <Grid container style={{ justifyContent: "center", height: "100%" }}>
          <Grid
            item
            style={{
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <CircularProgress
              color={"inherit"}
              style={{ color: THEME_ORANGE }}
            />
          </Grid>
        </Grid>
      </Modal>
      <AppRoutes />
      <Outlet />
    </ErrorBoundary>
  );
}

export default App;
