import moment from "moment";
import { useSelector } from "react-redux";
import { Alert, Button, Divider, Grid } from "@mui/material";
import React from "react";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import { IGlobalStoreState } from "../../../store/store";
import { API_HOSTNAME, THEME_ORANGE } from "../../../shared/constants";
import { ISubscriptionPlans } from "../../../interfaces";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface IAmpiousMemberShipInfo {
  gym_id: string;
  gym_owner_id: string;
  membership_start_date: string;
  membership_end_date: string;
  membership_plan_id: string;
  membership_plan_name: string;
  membership_state: string;
  payment_status: string;
  subscription_id: string;
  transaction_id: string;
  payment_method: string;
  created_on: Date;
  updated_on: Date;
}

export const MemberShipDetail = () => {
  const title = "Subscription";
  const callSerivce = new ServiceWrapper();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;
  const [memberShipDetail, setMembershipDetail] =
    React.useState<IAmpiousMemberShipInfo | null>(null);
  const token = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).token;

  const [subscriptionPlans, setSubscriptionPlans] = React.useState<
    ISubscriptionPlans[]
  >([]);

  React.useEffect(() => {
    if (user && gym && token) {
      getSubscriptionDetails();
      getPlansInfo();
      document.title = title;
    }
    getPlansInfo();
  }, [user, gym]);

  const getPlansInfo = () => {
    callSerivce.callGet(`${API_HOSTNAME}/admin/get_plans`).then((response) => {
      console.log("response", response.data);
      setSubscriptionPlans(response.data.data);
      // setShowUpgradeModal(true);
    });
  };

  const getSubscriptionDetails = () => {
    callSerivce
      .callPost(`${API_HOSTNAME}/gym_info/my-subscriptions`, {
        gym_id: gym?._id,
        gym_owner_id: user?._id,
      })
      .then((response) => {
        console.log("response", response.data);
        setMembershipDetail(response.data);
      });
  };

  const handlePayment = () => {
    window.alert(
      "Please complete the payment process in new window, you will be updated via mail once subscription starts",
    );
    callSerivce
      .callPost(`${API_HOSTNAME}/payments/initiate_subscription`, {
        gym_owner_id: user?._id,
        gym_id: gym?._id,
      })
      .then((response) => {
        console.log("response", response);
        if (response && response.data) {
          // setShowUpgradeModal(false);
          window.open(response.data.new_subscription.short_url, "_blank");

          // checkPaymentStatus();
        }
      });
  };

  return (
    <div className="h-screen" style={{ backgroundColor: "#fff" }}>
      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <div className="card-header d-flex justify-content-between border-bottom pb-1">
          Subscription details
        </div>
      </div>

      <Grid container direction="column" padding={"0 10px"} gap={2}>
        <Grid item container direction={"column"} gap={2}>
          {subscriptionPlans.map((plan) => {
            return (
              <>
                <Grid item>
                  <h5>
                    Plan Name: <b>{plan.plan_name}</b> (INR {plan.plan_price}
                    /month){" "}
                  </h5>
                </Grid>
                <Grid>
                  <Divider />
                </Grid>
                <Grid item gap={2}>
                  <h5 style={{ margin: "10px 0px" }}>Features: </h5>
                  <span style={{ marginTop: 10 }}>
                    {plan.features_included.map((feature) => {
                      return (
                        <Button
                          style={{
                            backgroundColor: THEME_ORANGE,
                            color: "#fff",
                            margin: "0 5px",
                          }}
                        >
                          <CheckCircleIcon />
                          {feature}
                        </Button>
                      );
                    })}
                    <ol
                      type="1"
                      style={{ margin: "10px 20px", listStyleType: "decimal" }}
                    >
                      <li>Add unlimited customers</li>
                      <li>Add unlimited Staff</li>
                      <li>Add unlimited Workouts</li>
                      <li>Add unlimited Plans</li>
                    </ol>
                    <h3> </h3>
                  </span>
                </Grid>
              </>
            );
          })}
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          {memberShipDetail && gym ? (
            <Grid container direction={"column"} gap={1}>
              <Grid item>
                <h4 style={{ margin: "5px 0px" }}>Active Plan details</h4>
              </Grid>
              <Grid item>
                <b>Plan Name:</b> {memberShipDetail.membership_plan_name}
              </Grid>
              <Grid item>
                <b>Plan Id:</b> {memberShipDetail.membership_plan_id}
              </Grid>
              <Grid item>
                <b>Subscription id:</b>{" "}
                {memberShipDetail.subscription_id || "NA"}
              </Grid>
              <Grid item>
                <b>Next due on:</b>{" "}
                {moment
                  .unix(
                    parseInt(
                      memberShipDetail.membership_start_date.substr(0, 10),
                    ),
                  )
                  .format("DD/MM/YYYY")}
              </Grid>
              <Grid item>
                <b>Membership state:</b> {memberShipDetail.membership_state}
              </Grid>
              <Grid item>
                <b>Payment method:</b>{" "}
                {memberShipDetail.payment_method.toUpperCase()}
              </Grid>
              <Grid item gap={2}>
                <Divider />
                <p style={{ marginTop: 5 }}>
                  To cancel, Please cancel the recurring payments from your
                  selected app
                  <br />
                  Once cancelled, then your data and your customer's data will
                  be deleted from the system in 30 days
                  <br />
                  You can restart the subscription, by mailing us your details
                  to <b>support@ampious.in</b>
                </p>
              </Grid>
              <Grid item>
                {(memberShipDetail.membership_state === "IN_TRIAL" ||
                  memberShipDetail.membership_state ===
                    "PROCESS_INITIATED") && (
                  <Button
                    fullWidth
                    variant="text"
                    style={{ backgroundColor: THEME_ORANGE, color: "#fff" }}
                    onClick={handlePayment}
                  >
                    &nbsp; Start subscription
                  </Button>
                )}
                {memberShipDetail.membership_state === "PROCESS_INITIATED" && (
                  <p style={{ marginTop: 10 }}>
                    <b>
                      Please don't pay again if you have already paid, We will
                      inform you over the mail for any issue.
                    </b>
                  </p>
                )}
              </Grid>
            </Grid>
          ) : (
            gym &&
            !memberShipDetail && (
              <Alert style={{ width: "100%" }} severity="warning">
                Please check after sometime
              </Alert>
            )
          )}
        </Grid>
      </Grid>
    </div>
  );
};
