import { Container, Grid, Typography } from "@mui/material";
import React from "react";

export const RefundsPolicy = () => {
  return (
    <Container
      style={{ backgroundColor: "#fff", padding: "10px 0", height: "100%" }}
    >
      <img
        alt="ampious-logo"
        src={
          "https://ampious.in/wp-content/uploads/2024/08/cropped-Ampious-transparent-logo-150x150.png"
        }
      />
      <Grid container>
        <Grid item mt={3} paddingLeft={5}>
          <Typography variant="h3">Refund Policy</Typography>

          <p>
            Ampious will not be providing any refunds once subscription has
            started.
          </p>

          <p>
            {" "}
            Ampious will be providing trial for 1 week (7 days) free of cost,
            during this time, user can access the portal.
          </p>

          <p>
            {" "}
            if user opts to not upgrade then all the data uploaded by you in
            your account will be deleted in 30 days.
          </p>

          <p>
            {" "}
            if you wish to upgrade the account before 30 days of when trial was
            over then you can do so by upgrading the subscription, whose amount
            will be visible to you on your dashboard.
          </p>
        </Grid>
      </Grid>
    </Container>
  );
};
