import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  TextField,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { IGymMemberShipPlan } from "../../../interfaces";
import { API_HOSTNAME } from "../../../shared/constants";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ServiceWrapper } from "../../../services/serviceWrapper";

export const GymMembershipPlan = () => {
  const title = "Membership Plans";
  const service = new ServiceWrapper();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;
  const [currentTab, setCurrentTab] = React.useState<number>(1);
  const [plans, setPlans] = React.useState<IGymMemberShipPlan[]>([]);

  const [plan, setPlan] = React.useState<IGymMemberShipPlan>({
    plan_name: "",
    gym_info_id: "",
    tenure: "",
    amount: "",
  });

  React.useEffect(() => {
    document.title = `Ampious : ${title}`;
  }, []);

  React.useEffect(() => {
    gym && user && getMyPlans();
  }, [gym, user]);

  const toggletabs = (event: React.SyntheticEvent, value: any) => {
    console.log("VAlue", value);
    if (value === "1") {
      getMyPlans();
    }
    setCurrentTab(value);
  };

  const setPlanForm = (field: string, value: string) => {
    setPlan({
      ...plan,
      [field]: value,
    });
  };

  const savePlan = () => {
    console.log("plan", plan);
    service
      .callPost(`${API_HOSTNAME}/gym_membership_plans/add_plan`, {
        ...plan,
        gym_info_id: gym!._id,
      })
      .then((response) => {
        console.log("ok");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getMyPlans = () => {
    console.log("plan", plan);
    service
      .callGet(
        `${API_HOSTNAME}/gym_membership_plans/my_plans?gym_id=${gym!._id}`,
      )
      .then((response) => {
        console.log("ok");
        setPlans(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const columns: GridColDef[] = [
    // { field: "_id", headerName: "_id", width: 250 },
    { field: "plan_name", headerName: "Plan name", width: 200 },
    { field: "tenure", headerName: "Tenure(in months)", width: 200 },
  ];

  return (
    <div className="h-screen">
      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <div className="card-header d-flex justify-content-between border-bottom pb-1">
          <Grid container justifyContent={"space-between"}>
            <Grid item>{title}</Grid>
          </Grid>
        </div>

        <TabContext value={currentTab.toString()}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={toggletabs} aria-label="lab API tabs example">
              <Tab label="Plans" value="1" />
              <Tab label="Add Plans" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <h2 className="mt-3 mb-3">Current Plans</h2>
            <DataGrid
              rows={plans}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              getRowId={(row) => row._id}
            />
          </TabPanel>
          <TabPanel value="2">
            <h2 className="mt-3">Add New Plans</h2>
            <div className="card-body">
              <div className="col-md-12">
                <TextField
                  type="text"
                  placeholder="Plan Name"
                  className="form-control"
                  onChange={(event) => {
                    setPlanForm("plan_name", event.target.value);
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <FormControl fullWidth>
                  <InputLabel>Tenure</InputLabel>
                  <Select
                    value={plan.tenure}
                    label="Tenue"
                    onChange={(event) => {
                      setPlanForm("tenure", event.target.value);
                    }}
                  >
                    <MenuItem value={1}>1 Month</MenuItem>
                    <MenuItem value={3}>3 Months</MenuItem>
                    <MenuItem value={6}>6 Months</MenuItem>
                    <MenuItem value={12}>12 Months</MenuItem>
                    <MenuItem value={18}>18 Months</MenuItem>
                    <MenuItem value={24}>24 Months</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-12 mt-3">
                <TextField
                  type="text"
                  placeholder="Amount"
                  className="form-control"
                  onChange={(event) => {
                    setPlanForm("amount", event.target.value);
                  }}
                />
              </div>

              <Button
                fullWidth
                variant="contained"
                type="submit"
                onClick={savePlan}
                className="btn btn-primary mt-2"
              >
                Save
              </Button>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};
