import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Divider,
} from "@mui/material";
import { IGymCustomer, IGymMemberShipPlanResponse } from "../interfaces";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { API_HOSTNAME, MOTIVES } from "./constants";
import { ServiceWrapper } from "../services/serviceWrapper";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../store/store";
import moment from "moment";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface INewCustomerModal {
  showAddCustomerModal: boolean;
  onCloseModal: () => void;
}

export const NewCustomerModal = (props: INewCustomerModal) => {
  const service = new ServiceWrapper();
  const [plans, setPlans] = React.useState<IGymMemberShipPlanResponse[]>([]);
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;
  const [isSubmitted, setIsSubmitted] = React.useState<Boolean>(false);
  const [customer, setCustomer] = React.useState<IGymCustomer>({
    first_name: "",
    last_name: "",
    gender: "",
    email: "",
    motive: "",
    gym_id: "",
    age: "",
    dob: "",
    membership_status: "active",
    membership_start_date: "",
    membership_end_date: "",
    phone_number: "",
    other_membership_detail: "",
    plan_id: "",
  });

  React.useEffect(() => {
    if (props.showAddCustomerModal) {
      getMyPlans();
      setIsSubmitted(false);
    }
  }, [props.showAddCustomerModal]);

  const setCustomerForm = (field: string, value: string) => {
    setCustomer({
      ...customer,
      [field]: value,
    });
  };

  React.useEffect(() => {
    console.log("hhh");
    const planId = customer?.plan_id;
    if (planId) {
      const planTenure = plans.find((p) => p._id === planId)?.tenure;
      console.log("planTenure", planTenure);
      if (planTenure) {
        const startDate = customer.membership_start_date
          ? moment(customer.membership_start_date)
          : moment();
        const endDate = moment(startDate).add(planTenure, "months");
        console.log(startDate, endDate);
        setCustomer({
          ...customer,
          membership_start_date: startDate.toString(),
          membership_end_date: endDate.toString(),
        });
      }
    }
  }, [customer.plan_id]);

  const getMyPlans = () => {
    service
      .callGet(
        `${API_HOSTNAME}/gym_membership_plans/my_plans?gym_id=${gym!._id}`,
      )
      .then((response) => {
        console.log("ok");
        setPlans(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const saveCustomer = () => {
    console.log("sss");
    setIsSubmitted(true);
    if (
      customer.first_name &&
      customer.last_name &&
      customer.gender &&
      customer.email &&
      customer.phone_number
    ) {
      {
        console.log("customer", customer);
        service
          .callPost(`${API_HOSTNAME}/gym_customer/register`, {
            ...customer,
            membership_end_date: moment(customer.membership_end_date).format(
              "DD/MM/YYYY",
            ),
            membership_start_date: moment(
              customer.membership_start_date,
            ).format("DD/MM/YYYY"),
            gym_id: gym?._id,
          })
          .then((response) => {
            props.onCloseModal();
            // Swal.fire({
            // 	icon: "success",
            // 	title: `Customer registered`,
            // 	showCancelButton: true,
            // }).then((response) => {
            // 	props.onCloseModal();
            // });
          })
          .catch((error) => {
            Swal.fire({
              icon: "success",
              title: `Some error occured while saving customer, please try again later`,
              showCancelButton: true,
            }).then((response) => {
              props.onCloseModal();
            });
          });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: `Please enter the required field`,
        showCancelButton: true,
      }).then((response) => {});
    }
  };

  return (
    <Dialog
      open={props.showAddCustomerModal}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xl"
      onClose={() => {}}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Add New Customer"}</DialogTitle>

      <DialogContent>
        <Grid container direction={"column"} justifyContent={"left"}>
          <h3>Add New Customer</h3>
          <Grid item paddingTop={2}>
            <FormControl
              fullWidth
              error={isSubmitted && !customer.first_name}
              variant="filled"
            >
              <TextField
                error={isSubmitted && !customer.first_name}
                fullWidth
                placeholder="Enter First Name"
                className="form-control"
                onChange={(event) => {
                  setCustomerForm("first_name", event.target.value);
                }}
              />
            </FormControl>
          </Grid>

          <Grid item paddingTop={2}>
            <FormControl
              fullWidth
              required={isSubmitted && !customer.last_name}
            >
              <TextField
                error={isSubmitted && !customer.last_name}
                type="text"
                placeholder="Enter Last Name"
                className="form-control"
                onChange={(event) => {
                  setCustomerForm("last_name", event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item paddingTop={2}>
            <TextField
              error={isSubmitted && !customer.email}
              type="text"
              placeholder="Enter Email"
              className="form-control"
              onChange={(event) => {
                setCustomerForm("email", event.target.value);
              }}
            />
          </Grid>

          <Grid item container justifyContent={"left"}>
            <Grid item paddingTop={2} flex={0.33}>
              <TextField
                error={isSubmitted && !customer.phone_number}
                type="text"
                inputProps={{ maxLength: 12 }}
                placeholder="Enter Phone number starting with 91"
                className="form-control"
                onChange={(event) => {
                  setCustomerForm("phone_number", event.target.value);
                }}
              />
            </Grid>

            <Grid item paddingTop={2} paddingLeft={2} flex={0.33}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  error={isSubmitted && !customer.gender}
                  label="Gender"
                  fullWidth
                  placeholder="Gender"
                  onChange={(e) => {
                    setCustomerForm("gender", e.target.value as string);
                  }}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item paddingTop={2} paddingLeft={2} flex={0.38}>
              <FormControl fullWidth>
                <InputLabel>Motive</InputLabel>
                <Select
                  label="Motive"
                  fullWidth
                  placeholder="Motive"
                  onChange={(e) => {
                    setCustomerForm("motive", e.target.value as string);
                  }}
                >
                  {MOTIVES.map((motive) => {
                    return (
                      <MenuItem value={motive.value}>{motive.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item paddingTop={2} paddingLeft={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ popper: { placement: "auto" } }}
                  format="DD/MM/YYYY"
                  label="Date of Birth"
                  onChange={(value) => {
                    value && setCustomerForm("dob", value.toDate().toString());
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>

        <Divider style={{ margin: "10px 0px" }} />
        <h3>Assign Gym Membership Plans</h3>
        <Grid container marginTop={2} justifyContent={"center"}>
          <Grid item flex={0.25}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Plan</InputLabel>
              <Select
                style={{ width: "100%" }}
                fullWidth
                onChange={(e) => {
                  setCustomerForm("plan_id", e.target.value as string);
                }}
              >
                {plans.map((plan) => {
                  return (
                    <MenuItem value={plan._id}>
                      {plan.plan_name}({plan.tenure} months)
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item flex={0.25} paddingLeft={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  value={dayjs(customer.membership_start_date)}
                  label="Membership Start Date"
                  onChange={(value) => {
                    value &&
                      setCustomerForm(
                        "membership_start_date",
                        value.toDate().toString(),
                      );
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item flex={0.25} paddingLeft={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  value={dayjs(customer.membership_end_date)}
                  label="Membership Valid till"
                  onChange={(value) => {
                    value &&
                      setCustomerForm(
                        "membership_end_date",
                        value.toDate().toString(),
                      );
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Divider />
        <Grid
          container
          justifyContent={"center"}
          mb={3}
          direction="row-reverse"
        >
          <Grid item flex={0.5} margin={"0 10px"}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                saveCustomer();
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid flex={0.5} margin={"0 10px"}>
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={() => {
                props.onCloseModal();
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
