import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import React from "react";
import { setUserAction } from "../../../store/actions";
import { API_HOSTNAME } from "../../../shared/constants";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import { IGymOwnerResponse } from "../../../interfaces";

export const Profile = () => {
  const title = "Profile Setting";
  const callService = new ServiceWrapper();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  document.title = `Ampious : ${title}`;

  const [profile, setProfileForm] = React.useState<IGymOwnerResponse | null>(
    null,
  );

  React.useEffect(() => {
    if (user) {
      setProfileForm({
        ...user,
      });
    }
  }, [user]);

  const uploadFile = (event: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      if (reader.result) {
        callService.uploadMedia(reader.result?.toString()).then((response) => {
          console.log("response", response.data.data.secure_url);
          if (profile) {
            setProfileForm({
              ...profile,
              avatar: response.data.data.secure_url,
            });
          }
        });
      }
    };
  };

  const updateProfile = async () => {
    console.log("profile", profile);
    try {
      callService
        .callPut(
          `${API_HOSTNAME}/gym-owner/update-profile/${profile?._id}`,
          profile,
        )
        .then((response) => {
          console.log("response", response);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          setUserAction(response.data.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {profile ? (
        <div className="container-fluid">
          <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
            <div className="card-header d-flex justify-content-between border-bottom pb-1">
              <div className="">{title} </div>
            </div>
            <div className="card-body">
              <div className="row ">
                <div className="col-md-6 mt-3">
                  <label>
                    <b>Full Name</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Name"
                    value={profile!.fname}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>
                    <b>User Name</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Email User Name"
                    value={profile!.lname}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <label>
                    <b>Email</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Email"
                    value={profile!.email}
                    className="form-control"
                  />
                </div>

                <div className="mt-3">
                  <p className="mb-0">
                    <b>Upload Profile Picture</b>
                  </p>
                  <img
                    src={profile.avatar}
                    style={{ width: 150, height: 150 }}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <input
                    type="file"
                    className="form-control"
                    onChange={uploadFile}
                  />
                </div>
              </div>
              <button
                onClick={updateProfile}
                className="btn btn-primary btn-sm mt-3"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
