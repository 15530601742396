import * as React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { IGymOwner } from "../interfaces/IGymOwner";

import { API_HOSTNAME } from "../shared/constants";
import {
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../assets/photo/image.png";
import { ServiceWrapper } from "../services/serviceWrapper";

export const Register = () => {
  const title = "Register";
  const navigate = useNavigate();
  const service = new ServiceWrapper();

  const [form, setForm] = React.useState<IGymOwner>({
    fname: "",
    lname: "",
    phoneNo: "",
    email: "",
    password: "",
    avatar: "",
  });

  document.title = `Ampious : ${title}`;

  const registerHanle = () => {
    console.log("API_HOSTNAME", API_HOSTNAME);
    if (
      !form.fname ||
      !form.lname ||
      !form.phoneNo ||
      !form.email ||
      !form.password
    ) {
      Swal.fire(
        "Register error",
        "Please enter all the required field",
        "error",
      );
    } else {
      service
        .callPost(`${API_HOSTNAME}/gym-owner/register`, form)
        .then((response) => {
          if (response) {
            console.log("response", response);
            Swal.fire("Register success", "", "success");
            navigate("/public/login");
          }
        })
        .catch(() => {
          // alert('')
          Swal.fire(
            "Register error",
            "This email id/phone number is already registered, please login or use another email",
            "error",
          );
        });
    }
  };

  const updateForm = (field: string, value: string) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  return (
    <>
      <Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Container
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-5">
            <div className="card">
              <div className="card-header text-center">
                <img
                  src={logo}
                  alt="ampious-logo"
                  width={"25%"}
                  style={{ alignSelf: "center" }}
                />
              </div>
              <div className="card-header  text-center">
                <Typography variant="h4">Register</Typography>
              </div>
              <div className="card-body">
                <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                  <TextField
                    label="First Name"
                    type="text"
                    placeholder="Enter Your First Name"
                    onChange={(event) => {
                      updateForm("fname", event.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                  <TextField
                    label="Last Name"
                    type="text"
                    placeholder="Enter Your Last Name"
                    onChange={(event) => {
                      updateForm("lname", event.target.value);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                  <TextField
                    label="Email"
                    type="text"
                    placeholder="Enter Your Email"
                    onChange={(event) => {
                      updateForm("email", event.target.value);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                  <TextField
                    label="Phone Number"
                    type="text"
                    inputProps={{
                      maxLength: 12,
                    }}
                    placeholder="Enter Your Phone Number"
                    onChange={(event) => {
                      updateForm("phoneNo", event.target.value);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                  <TextField
                    label="Password"
                    type="password"
                    placeholder="Enter Password"
                    onChange={(event) => {
                      updateForm("password", event.target.value);
                    }}
                  />
                </FormControl>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button onClick={registerHanle} variant="contained">
                    Register
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ width: "8rem" }}
                    onClick={() => navigate("/public/login")}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};
