import { useState, useEffect } from "react";
import { IStaffShift } from "../../../interfaces/IStaffShift";
import {
  InputLabel,
  Select,
  MenuItem,
  Container,
  Button,
  paginationClasses,
  Typography,
  Grid,
  Box,
  Tab,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { IGymInfoResponse } from "../../../interfaces";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_HOSTNAME } from "../../../shared/constants";
import { IGlobalStoreState } from "../../../store/store";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";

function getCurrentDateTime() {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}
export const StaffShift = () => {
  const { customer_id } = useParams();
  const title = "Staff Shift";
  document.title = `Ampious : ${title}`;
  const serviceCall = new ServiceWrapper();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [currentStaffShift, setCurrentStaffShift] = useState<IStaffShift[]>([]);
  const [staffShift, setStaffShift] = useState<IStaffShift>({
    staff_user_id: customer_id || "",
    shift_starts_time: "",
    shift_ends_time: "",
    shift_updated_on: "",
    shift_updated_by: "",
    gym_id: "",
  });

  const [staffName, setStaffName] = useState<[{}]>([{}]);
  useEffect(() => {
    if (gym && gym!._id && user) {
      setStaffShift({
        ...staffShift,
        gym_id: gym!._id,
        shift_updated_by: user!._id,
      });
      getGymStaffId();
    }
  }, [user, gym]);

  const getGymStaffId = async () => {
    if (gym!._id) {
      const response = await axios.get(
        `${API_HOSTNAME}/gym-staff/get-my-staff/${gym!._id}`,
      );
      console.log(response.data);
      setStaffName(response.data.data);
    }
  };

  const timeOptions: string[] = Array.from({ length: 24 }, (_, index) => {
    const hours = String(index).padStart(2, "0");
    const minutes = "00";
    return `${hours}:${minutes}`;
  });

  const handleSubmit = async () => {
    const data = {
      ...staffShift,
      shift_updated_on: getCurrentDateTime(),
    };
    try {
      serviceCall
        .callPost(`${API_HOSTNAME}/gym-staff/add-staff-shifts`, data)
        .then((response) => {
          console.log(response);
          setStaffShift({
            staff_user_id: staffShift.staff_user_id,
            shift_starts_time: "",
            shift_ends_time: "",
            shift_updated_on: "",
            shift_updated_by: "",
            gym_id: "",
          });
        })
        .catch(() => {
          Swal.fire("Please try again later", "", "error");
        });
    } catch (error) {
      Swal.fire("Error", "", "error");
    }
  };

  const toggletabs = (event: React.SyntheticEvent, value: string) => {
    setCurrentTab(Number(value));
    if (value === "0") {
      getGymStaffId();
    }
  };

  const getCurrentStaffShift = async () => {
    const response = await serviceCall.callGet(
      `${API_HOSTNAME}/gym-staff/get-staff-shifts-info/${staffShift.staff_user_id}`,
    );
    console.log("this is response", response.data);
    setCurrentStaffShift(response.data.data);
  };

  useEffect(() => {
    gym && user && getCurrentStaffShift();
  }, [gym, user]);

  const shiftColumns: GridColDef[] = [
    {
      field: "shift_starts_time",
      headerName: "Start Time",
      width: 150,
    },
    {
      field: "shift_ends_time",
      headerName: "End Time",
      width: 150,
    },
    {
      field: "shift_updated_on",
      headerName: "Updated On",
      width: 250,
    },
    {
      field: "updated_by_info",
      headerName: "Updated By",
      width: 250,
      renderCell: (params) => {
        return params.value && params.value.length > 0
          ? params.value[0].fname + params.value[0].lname
          : "";
      },
    },
  ];

  return (
    <div className="h-screen" style={{ backgroundColor: "#fff" }}>
      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <div className="card-header d-flex justify-content-between border-bottom pb-1">
          <Grid
            container
            padding={"0.5rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>{title}</Grid>
          </Grid>
        </div>
      </div>

      <TabContext value={currentTab.toString()}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={toggletabs} aria-label="simple tabs example">
            <Tab label="View Staff Shift" value="0" />
            <Tab label="Add Staff Shift" value="1" />
          </TabList>
        </Box>

        <TabPanel value="0">
          <h2 className="mt-3 mb-3">My Staff</h2>
          <DataGrid
            rows={currentStaffShift}
            columns={shiftColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  page: 0,
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            getRowId={(row) => row._id}
          />
        </TabPanel>

        <TabPanel value="1">
          <h2 className="mt-3">Add Shift of Staff</h2>
          <div className="mt-3">
            <FormControl
              sx={{
                width: "500px",
              }}
            >
              <InputLabel>Select Staff</InputLabel>
              <Select
                value={staffShift.staff_user_id}
                label="Staff Id "
                onChange={(event) => {
                  setStaffShift({
                    ...staffShift,
                    staff_user_id: event.target.value,
                  });
                }}
              >
                {staffName.map((item: any) => (
                  <MenuItem value={item._id}>{item.first_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="mt-3 ">
            {" "}
            <FormControl
              sx={{
                width: "500px",
              }}
            >
              <InputLabel id="time-label">Start Time</InputLabel>
              <Select
                labelId="time-label"
                id="time-select"
                value={staffShift.shift_starts_time}
                label="Time"
                onChange={(event) => {
                  setStaffShift({
                    ...staffShift,
                    shift_starts_time: event.target.value,
                  });
                }}
              >
                {timeOptions.map((time) => (
                  <MenuItem
                    sx={{
                      maxWidth: "350px",
                    }}
                    value={time}
                  >
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="mt-3 ">
            {" "}
            <FormControl
              sx={{
                width: "500px",
              }}
            >
              <InputLabel id="time-label">End Time </InputLabel>
              <Select
                labelId="time-label"
                id="time-select"
                value={staffShift.shift_ends_time}
                label="Time"
                onChange={(event) => {
                  setStaffShift({
                    ...staffShift,
                    shift_ends_time: event.target.value,
                  });
                }}
              >
                {timeOptions.map((time) => (
                  <MenuItem
                    sx={{
                      maxWidth: "350px",
                    }}
                    value={time}
                  >
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button className="mt-5" variant="contained" onClick={handleSubmit}>
            Add shift
          </Button>
        </TabPanel>
      </TabContext>
    </div>
  );
};
