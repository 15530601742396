import "../../../assets/layout.css";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faX,
  faUsers,
  faHomeUser,
  faRoadCircleXmark,
  faDumbbell,
  faPerson,
  faPersonChalkboard,
  faRestroom,
  faTowerBroadcast,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import logo from "../../../assets/photo/image-removebg-preview.png";
import {
  setGymInfoAction,
  setGymStaffAction,
  setMemberShipInfoAction,
  setUserAction,
} from "../../../store";
import { Button, Container, Dialog, Divider, Modal } from "@mui/material";
import React from "react";
import VisitorForm from "../../../components/VisitorForm";

export const GymStaffLayouts = () => {
  const pathname = window.location.pathname;
  const service = new ServiceWrapper();
  const [headerToggle, setHeaderToggle] = useState(false);
  const [sidebar, setSidebar] = useState<boolean>(false);
  const [headerNavManu, setheaderNavManu] = useState(true);
  const [showAddVisitorModal, setshowAddVisitorModal] =
    React.useState<boolean>(false);

  const staff = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).staffUser;

  useEffect(() => {
    if (pathname.includes("public")) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  }, [pathname]);

  const headerTogglehandle = () => {
    setHeaderToggle(!headerToggle);
    setheaderNavManu(!headerNavManu);
  };
  var body_pd = document.getElementById("body-pd");

  useEffect(() => {
    window.innerWidth >= 768 &&
      (headerToggle
        ? body_pd?.classList.add("body-pd")
        : body_pd?.classList.remove("body-pd"));
    if (document.getElementById("header") !== null) {
      headerToggle &&
        document.getElementById("header")?.classList.add("body-pd");
      headerToggle !== true &&
        document.getElementById("header")?.classList.remove("body-pd");
    }
  }, [headerToggle]);

  const logoutHandle = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(() => {
      localStorage.removeItem("gym-staff");
      localStorage.removeItem("token");
      localStorage.removeItem("user-type");

      setGymStaffAction(null);
      window.location.href = "/public/gym-staff/login";
    });
  };

  return (
    <div className="">
      <div className={!sidebar ? "d-none" : ""}>
        <header className="header mb-4 dropdown" id="header">
          <div
            onClick={headerTogglehandle}
            className="header_toggle"
            id="header-toggle"
          >
            {headerNavManu ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faX} />
            )}{" "}
          </div>

          <Dialog open={showAddVisitorModal} style={{ padding: 10 }}>
            <Container style={{ padding: 10 }}>
              <VisitorForm
                onSave={() => setshowAddVisitorModal(false)}
                showCancelButton={true}
                onCancel={() => {
                  setshowAddVisitorModal(false);
                }}
              />
            </Container>
          </Dialog>

          {staff && (
            <div
              className="d-flex align-items-center dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <span className="header_img">
                {/* <img src={staff?.} alt="Admin" /> */}
              </span>{" "}
              <span className="ms-1">
                {staff!.first_name} {staff!.last_name}
              </span>{" "}
            </div>
          )}

          <ul
            className="dropdown-menu dropdown-menu-end"
            style={{ width: "auto", padding: "0, 2rem" }}
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <Link className="dropdown-item" to="/gym-staff/profileSetting">
                Profile
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="/gym-staff/change-password"
                target="_self"
              >
                Change Password
              </Link>
            </li>

            <li className="dropdown-item" onClick={logoutHandle}>
              Log Out
            </li>
            <li>
              <Divider />
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="/public/terms-and-conditions"
                target="_self"
              >
                Terms And Conditions
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="/public/refunds-policy"
                target="_self"
              >
                Refund Policy
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="/public/privacy-policy"
                target="_self"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="/public/about-us"
                target="_self"
              >
                About Us
              </Link>
            </li>

            <li>
              <Link className="dropdown-item" to="/public/contact-us">
                Contact Us
              </Link>
            </li>
          </ul>
        </header>

        <div className="manubar">
          <div
            className={`l-navbar ${headerToggle ? "show" : " "}`}
            id="nav-bar"
          >
            <nav className="nav">
              <div>
                {!headerToggle ? (
                  <div className=" flex justify-start items-center ml-2 mb-2">
                    <div className=" w-14 h-14 overflow-hidden p-2 ">
                      <img src={logo} alt="" width={50} height={50} />
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <div className="h-24 w-24 flex justify-center items-center">
                      <img src={logo} alt="" width={50} height={50} />
                    </div>
                  </div>
                )}{" "}
                <div className="nav_list">
                  <Link
                    to="/gym-staff/dashboard"
                    className={`nav_link ${pathname === "/" || pathname === "/gym-staff/dashboard" ? "active" : ""}`}
                  >
                    <i className="bx bx-grid-alt nav_icon"></i>
                    <span className="nav_name">Dashboard</span>
                  </Link>
                  <Link
                    to="/gym-staff/gym-info"
                    className={`nav_link ${pathname === "/gym-staff/gym-info" ? "active" : ""}`}
                  >
                    <FontAwesomeIcon icon={faHomeUser} />
                    <span className="nav_name">My Gym</span>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
