import "../../../assets/layout.css";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faX,
  faUsers,
  faHomeUser,
  faRoadCircleXmark,
  faDumbbell,
  faPerson,
  faPersonChalkboard,
  faRestroom,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import logo from "../../../assets/photo/image-removebg-preview.png";
import {
  setGymInfoAction,
  setMemberShipInfoAction,
  setUserAction,
} from "../../../store";
import { Button, Container, Dialog, Divider, Grid } from "@mui/material";
import React from "react";
import VisitorForm from "../../../components/VisitorForm";
import { THEME_ORANGE } from "../../../shared/constants";

export default function GymOwnerLayouts() {
  const pathname = window.location.pathname;
  const navigate = useNavigate();

  const [headerToggle, setHeaderToggle] = useState(false);
  const [sidebar, setSidebar] = useState<boolean>(false);
  const [headerNavManu, setheaderNavManu] = useState(true);
  const [showAddVisitorModal, setshowAddVisitorModal] =
    React.useState<boolean>(false);
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;

  useEffect(() => {
    if (pathname.includes("public")) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  }, [pathname]);

  const headerTogglehandle = () => {
    setHeaderToggle(!headerToggle);
    setheaderNavManu(!headerNavManu);
  };
  var body_pd = document.getElementById("body-pd");

  useEffect(() => {
    window.innerWidth >= 768 &&
      (headerToggle
        ? body_pd?.classList.add("body-pd")
        : body_pd?.classList.remove("body-pd"));
    if (document.getElementById("header") !== null) {
      headerToggle &&
        document.getElementById("header")?.classList.add("body-pd");
      headerToggle !== true &&
        document.getElementById("header")?.classList.remove("body-pd");
    }
  }, [headerToggle]);

  const logoutHandle = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(() => {
      /* Read more about isConfirmed, isDenied below */
      // logoutUserAction();
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("user-type");
      localStorage.removeItem("gym_info");
      localStorage.removeItem("member_ship_details");

      setUserAction(null);
      setMemberShipInfoAction("");
      setGymInfoAction("");
      // navigate("/public/login");
      window.location.href = "/public/login";
    });
  };

  return (
    <div className="">
      <div className={!sidebar ? "d-none" : ""}>
        <header className="header mb-4 dropdown" id="header">
          <div
            onClick={headerTogglehandle}
            className="header_toggle"
            id="header-toggle"
          >
            {headerNavManu ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faX} />
            )}{" "}
          </div>

          <Dialog open={showAddVisitorModal} style={{ padding: 10 }}>
            <Container style={{ padding: 10 }}>
              <VisitorForm
                onSave={() => setshowAddVisitorModal(false)}
                showCancelButton={true}
                onCancel={() => {
                  setshowAddVisitorModal(false);
                }}
              />
            </Container>
          </Dialog>
          <Grid
            justifyContent={"flex-end"}
            direction="row"
            container
            sx={{
              display: { xs: "none", lg: "flex", sm: "flex", md: "flex" },
            }}
          >
            <Grid item>
              <Button
                color="inherit"
                style={{ color: THEME_ORANGE }}
                variant="text"
                onClick={() => navigate("/gym-owner/membership-details")}
              >
                My Subscriptions
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="inherit"
                style={{ color: THEME_ORANGE }}
                variant="text"
                onClick={() => setshowAddVisitorModal(true)}
              >
                Add Visitor
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="inherit"
                style={{ color: THEME_ORANGE }}
                variant="text"
                onClick={() => navigate("/gym-owner/staff")}
              >
                View Staff Attendance
              </Button>
            </Grid>
          </Grid>

          {user && (
            <div
              className="d-flex align-items-center dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <span className="header_img">
                <img src={user?.avatar} alt="Admin" />
              </span>{" "}
              <span className="ms-1">
                {user!.fname} {user!.lname}
              </span>{" "}
            </div>
          )}

          <ul
            className="dropdown-menu dropdown-menu-end"
            style={{ width: "auto", padding: "0, 2rem" }}
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <Link className="dropdown-item" to="/gym-owner/profileSetting">
                Profile
              </Link>
            </li>
            {/* <li>
							<Link className="dropdown-item" to="/general-setting">
								Setting
							</Link>
						</li> */}
            <li>
              <Link
                className="dropdown-item"
                to="/gym-owner/change-password"
                target="_self"
              >
                Change Password
              </Link>
            </li>

            <li>
              <Link
                className="dropdown-item"
                to="/gym-owner/membership-details"
              >
                Subscriptions
              </Link>
            </li>

            <li className="dropdown-item" onClick={logoutHandle}>
              Log Out
            </li>
            <li>
              <Divider />
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="/public/terms-and-conditions"
                target="_self"
              >
                Terms And Conditions
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="/public/refunds-policy"
                target="_self"
              >
                Refund Policy
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="/public/privacy-policy"
                target="_self"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="/public/about-us"
                target="_self"
              >
                About Us
              </Link>
            </li>

            <li>
              <Link className="dropdown-item" to="/public/contact-us">
                Contact Us
              </Link>
            </li>
          </ul>
        </header>

        <div className="manubar">
          <div
            className={`l-navbar ${headerToggle ? "show" : " "}`}
            id="nav-bar"
          >
            <nav className="nav">
              <div>
                {!headerToggle ? (
                  <div className=" flex justify-start items-center ml-2 mb-2">
                    <div className=" w-14 h-14 overflow-hidden p-2 ">
                      <img src={logo} alt="" width={50} height={50} />
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <div className="h-24 w-24 flex justify-center items-center">
                      <img src={logo} alt="" width={50} height={50} />
                    </div>
                  </div>
                )}{" "}
                <div className="nav_list">
                  <Link
                    to="/gym-owner/dashboard"
                    className={`nav_link ${pathname === "/" || pathname === "/gym-owner/dashboard" ? "active" : ""}`}
                  >
                    <i className="bx bx-grid-alt nav_icon"></i>
                    <span className="nav_name">Dashboard</span>
                  </Link>
                  <Link
                    to="/gym-owner/gym-info"
                    className={`nav_link ${pathname === "/gym-owner/gym-info" ? "active" : ""}`}
                  >
                    <FontAwesomeIcon icon={faHomeUser} />
                    <span className="nav_name">My Gym</span>
                  </Link>
                  <Link
                    to="gym-owner/visitor-management"
                    className={`nav_link ${pathname === "/gym-owner/visitor-management" ? "active" : ""}`}
                  >
                    <FontAwesomeIcon icon={faRestroom} />
                    <span className="nav_name">My Vistors</span>
                  </Link>
                  <Link
                    to="/gym-owner/gym-customers"
                    className={`nav_link ${pathname === "/gym-owner/gym-customers" ? "active" : ""}`}
                  >
                    <FontAwesomeIcon icon={faUsers} />
                    <span className="nav_name">Manage Customers</span>
                  </Link>
                  <Link
                    to="/gym-owner/gym-membership-plan"
                    className={`nav_link ${pathname === "/gym-owner/gym-membership-plan" ? "active" : ""}`}
                  >
                    <FontAwesomeIcon icon={faRoadCircleXmark} />
                    <span className="nav_name">Membership plans</span>
                  </Link>
                  <Link
                    to="/gym-owner/workout-manager"
                    className={`nav_link ${pathname === "/gym-owner/workout-manager" ? "active" : ""}`}
                  >
                    <FontAwesomeIcon icon={faDumbbell} />
                    <span className="nav_name">Workouts manager</span>
                  </Link>
                  <Link
                    to="/gym-owner/staff"
                    className={`nav_link ${pathname === "/gym-owner/staff" ? "active" : ""}`}
                  >
                    <FontAwesomeIcon icon={faPerson} />
                    <span className="nav_name">Manage Staff</span>
                  </Link>
                  <Link
                    to="/gym-owner/add-classes"
                    className={`nav_link ${pathname === "/gym-owner/add-classes" ? "active" : ""}`}
                  >
                    <FontAwesomeIcon icon={faPersonChalkboard} />
                    <span className="nav_name">Gym Classes</span>
                  </Link>
                  {/* <Link to="/gym-owner/add-edit-events" className={`nav_link ${pathname === "/gym-owner/add-edit-events" ? "active" : ""}`}>
										<FontAwesomeIcon icon={faTowerBroadcast} />
										<span className="nav_name">Events</span>
									</Link> */}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
