import { API_HOSTNAME } from "../../../shared/constants";
import { useEffect, useState } from "react";
import { IStaffDetail } from "../../../interfaces/IStaffDetail";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  TextField,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const Staff = () => {
  const title = "Staffs";
  const navigate = useNavigate();
  const [staff, setStaff] = useState<IStaffDetail[]>([]);
  const callService = new ServiceWrapper();
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;
  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;

  const [designation, setDesignation] = useState([]);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [staffForm, setStaffForm] = useState<IStaffDetail>({
    first_name: "",
    last_name: "",
    gender: "",
    email: "",
    phone_no: "",
    gym_id: "",
    designation: "",
    certification_info: "",
    password: "",
  });

  useEffect(() => {
    document.title = `Ampious : ${title}`;
  }, []);

  useEffect(() => {
    if (gym) {
      getStaff();
    }
  }, [gym]);

  const toggletabs = (event: React.SyntheticEvent, value: string) => {
    console.log("VAlue", value);
    setCurrentTab(Number(value));
    if (value === "0") {
      getStaff();
    }
  };

  const setStaffValue = (field: string, value: string) => {
    setStaffForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const addStaff = () => {
    console.log(staffForm);
    callService
      .callPost(`${API_HOSTNAME}/gym-staff/add-staff`, {
        ...staffForm,
        gym_id: gym?._id,
        phone_number: staffForm.phone_no,
      })
      .then((response) => {
        Swal.fire("Staff Added Successfully", "", "success");
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire("Email Already Exists ", "", "error");
      });
  };

  const getStaff = () => {
    console.log(staff);
    callService
      .callGet(`${API_HOSTNAME}/gym-staff/get-my-staff/${gym!._id}`)
      .then((response) => {
        console.log("Get Staff Response", response);
        setStaff(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const navigateWithCustomerId = (customerId: string) => {
    navigate(`/gym-owner/staff-shift/${customerId}`);
  };
  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First Name",
      width: 100,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phone_no",
      headerName: "Phone No",
      width: 200,
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 150,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <Grid container gap={2}>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              sx={{ backgroundColor: "#007bff", color: "white" }}
              onClick={() => {
                navigateWithCustomerId(params.row._id);
                console.log("This is Row Id", params.row._id);
              }}
            >
              Shift
            </Button>
          </Grid>
          <Grid>
            <Button
              color={"info"}
              variant="contained"
              size="small"
              sx={{ color: "white" }}
              onClick={() => {
                navigate(
                  "/gym-owner/staff-attendence?staff_id=" + params.row._id,
                );
              }}
            >
              Attendance
            </Button>
          </Grid>
          <Grid>
            <Button
              color={"error"}
              variant="contained"
              size="small"
              sx={{ color: "white" }}
              onClick={() => {
                removeStaff(params.row._id, params.row.first_name);
                console.log("This is Row Id", params.row._id);
              }}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      ),
    },
  ];

  const removeStaff = (id: string, name: string) => {
    Swal.fire({
      icon: "warning",
      title: `Are you sure to delete staff ${name} ?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((response) => {
      console.log("response", response);
      if (response.isConfirmed) {
        callService
          .callDelete(`${API_HOSTNAME}/gym-staff/remove-staff/${id}`, {})
          .then((response) => {
            getStaff();
          });
      }
    });
  };

  useEffect(() => {
    const getDesignation = async () => {
      try {
        const response = await callService.callGet(
          `${API_HOSTNAME}/master/designations`,
        );
        setDesignation(response.data[0]);
        console.log(response);
      } catch (error) {
        console.log("error", error);
      }
    };
    if (user) {
      getDesignation();
    }
  }, [user]);

  console.log("This is Designation", typeof designation);
  return (
    <div className="h-screen" style={{ backgroundColor: "#fff" }}>
      <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
        <div className="card-header d-flex justify-content-between border-bottom pb-1">
          <Grid
            container
            padding={"0.5rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>{title}</Grid>
          </Grid>
        </div>
      </div>
      <TabContext value={currentTab.toString()}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={toggletabs} aria-label="simple tabs example">
            <Tab label="View Staff" value="0" />
            <Tab label="Add Staff" value="1" />
          </TabList>
        </Box>

        <TabPanel value="0">
          <h2 className="mt-3 mb-3">My Staff</h2>
          <DataGrid
            rows={staff}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  page: 0,
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            getRowId={(row) => row._id}
          />
        </TabPanel>
        <TabPanel value="1">
          <h2 className="mt-3">Add Staff</h2>

          <Grid container direction="column" gap={2} mt={5}>
            <Grid item>
              <TextField
                type="text"
                placeholder="First Name"
                className="form-control"
                onChange={(event) => {
                  setStaffValue("first_name", event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                type="text"
                placeholder="Last Name"
                className="form-control"
                onChange={(event) => {
                  setStaffValue("last_name", event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  value={staffForm.gender}
                  label="Gender"
                  onChange={(event) => {
                    console.log(staffForm);
                    setStaffValue("gender", event.target.value);
                  }}
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                type="email"
                placeholder="Email"
                className="form-control"
                onChange={(event) => {
                  setStaffValue("email", event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                inputProps={{
                  maxLength: 12,
                }}
                type="text"
                placeholder="Phone Number"
                className="form-control"
                onChange={(event) => {
                  setStaffValue("phone_no", event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel>Designation</InputLabel>
                <Select
                  value={staffForm.designation}
                  label="Designation"
                  onChange={(event) => {
                    console.log(staffForm);
                    setStaffValue("designation", event.target.value);
                  }}
                >
                  {Object.values(designation).map((items: string) => (
                    <MenuItem value={items}>{items}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                type="text"
                placeholder="Certification Info"
                className="form-control"
                onChange={(event) => {
                  setStaffValue("certification_info", event.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Button
            fullWidth
            variant="contained"
            type="submit"
            onClick={addStaff}
            className="btn btn-primary mt-2"
          >
            Save
          </Button>
        </TabPanel>
      </TabContext>
    </div>
  );
};
