import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  createFilterOptions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { API_HOSTNAME } from "../../../shared/constants";
import axios, { AxiosResponse } from "axios";
import {
  IGymCustomerMemberShipResponse,
  IMasterExercises,
} from "../../../interfaces";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ServiceWrapper } from "../../../services/serviceWrapper";
import { CustomButton, CustomDialog } from "../../../shared";
import * as randomstring from "randomstring";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useSelector } from "react-redux";
import { IGlobalStoreState } from "../../../store/store";

interface IWorkoutPlan {
  _id: string;
  plan_name: string;
  gym_id: string;
  is_public: boolean;
  major_focused: string;
  created_on: Date;
  updated_on: Date;
}

interface IWorkoutSet {
  _id?: string;
  new_record: boolean;
  day: string;
  exercise_id: string;
  exercise_name: string;
  reps: number;
  sets: number;
  workout_plan_id: string;
  duration_in_minutes: number;
  workout_category: string;
  created_on: Date;
  updated_on: Date;
}

export const WorkoutPlanEditor = () => {
  const params = useParams();
  const service = new ServiceWrapper();
  const navigate = useNavigate();

  const title = "Workout Editor";
  const [planDetails, setPlanDetails] = React.useState<IWorkoutPlan | null>(
    null,
  );
  const [exerciseList, setExerciseList] = React.useState<IMasterExercises[]>(
    [],
  );
  const [exerciseCategory, setExerciseCategory] = React.useState<string[]>([]);
  const [isModelOpen, setisModelOpen] = React.useState<boolean>(false);
  const [newExerciseName, setNewExerciseName] = React.useState<string>("");
  const [customers, setCustomers] = React.useState<
    IGymCustomerMemberShipResponse[]
  >([]);
  const [searchKeyword, setSearchKeyword] = React.useState<string>("");
  const user = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).user;

  const gym = useSelector(
    (state: IGlobalStoreState) => state.userReducer,
  ).gymInfo;

  const [singleWorkoutSet, setSingleWorkoutSet] = React.useState<IWorkoutSet>({
    new_record: true,
    day: "monday",
    exercise_name: "",
    exercise_id: "",
    reps: 10,
    sets: 3,
    workout_plan_id: "",
    duration_in_minutes: 10,
    workout_category: "CARDIO",
    created_on: new Date(),
    updated_on: new Date(),
  });
  const [workoutSets, setWorkoutSets] = React.useState<IWorkoutSet[]>([]);

  React.useEffect(() => {
    document.title = `Ampious : ${title}`;
    const planId = params.plan_id || "";
    getAllExercises();
    getWorkoutCategories();
    getAllCustomers();
    setSingleWorkoutSet({
      ...singleWorkoutSet,
      workout_plan_id: planId,
    });
    planId && getPlanDetails(planId);
  }, []);

  const getPlanDetails = (planId: string) => {
    axios
      .get(`${API_HOSTNAME}/workouts/get_plan_details/${planId}`)
      .then((response: AxiosResponse) => {
        setPlanDetails(response.data.data.workoutPlan);
        setWorkoutSets(response.data.data.workoutSetDetails);
      });
  };
  const getAllExercises = () => {
    service
      .callGet(`${API_HOSTNAME}/workouts/get_all_exercises`)
      .then((response: AxiosResponse) => {
        setExerciseList(response.data.data);
      });
  };

  const getWorkoutCategories = () => {
    service
      .callGet(`${API_HOSTNAME}/master/workout-categories`)
      .then((response) => {
        setExerciseCategory(response.data);
      });
  };

  const getWorkoutsForday = (day: string): IWorkoutSet[] => {
    if (planDetails) {
      const filteredSets = workoutSets.filter(
        (set) => set.day.toLowerCase() === day.toLowerCase(),
      );
      return filteredSets;
    } else {
      return [];
    }
  };

  const addExerciseToWorkOut = () => {
    if (!singleWorkoutSet.exercise_id || !singleWorkoutSet.exercise_name) {
      Swal.fire(
        "Required fields missing",
        "Please enter exercise Name",
        "error",
      );
      return;
    }
    const alreadyExists = workoutSets.find(
      (set) =>
        set.exercise_id === singleWorkoutSet.exercise_id &&
        set.day === singleWorkoutSet.day,
    );
    if (alreadyExists) {
      Swal.fire(
        "Already Exists",
        "you have already added this exercise for this day",
        "error",
      );
    } else {
      if (
        singleWorkoutSet.day &&
        singleWorkoutSet.reps &&
        singleWorkoutSet.sets
      ) {
        setWorkoutSets([
          ...workoutSets,
          {
            _id: randomstring.generate(),
            new_record: true,
            exercise_name: singleWorkoutSet.exercise_name,
            day: singleWorkoutSet.day,
            exercise_id: singleWorkoutSet.exercise_id,
            reps: singleWorkoutSet.reps,
            sets: singleWorkoutSet.sets,
            workout_plan_id: singleWorkoutSet.workout_plan_id,
            duration_in_minutes: singleWorkoutSet.duration_in_minutes,
            workout_category: singleWorkoutSet.workout_category,
            created_on: new Date(),
            updated_on: new Date(),
          },
        ]);
        setSingleWorkoutSet({
          new_record: true,
          exercise_name: "",
          day: "monday",
          exercise_id: "",
          reps: 10,
          sets: 3,
          workout_plan_id: singleWorkoutSet.workout_plan_id,
          duration_in_minutes: 10,
          workout_category: "CARDIO",
          created_on: new Date(),
          updated_on: new Date(),
        });
      } else {
        Swal.fire(
          "Required fields missing",
          "please enter day, reps and sets",
          "error",
        );
      }
    }
  };
  const removeExercise = (setId: string, day: string) => {
    const filteredSets = workoutSets.filter((set) => set._id !== setId);
    setWorkoutSets(filteredSets);
  };

  const savePlan = () => {
    Swal.fire("Confirm", "Are you done saving the workout Plan ?", "question")
      .then((value) => {
        console.log("value", value);
        axios
          .post(`${API_HOSTNAME}/workouts/save_new_plan`, {
            plan_id: planDetails?._id,
            workout_sets: workoutSets,
          })
          .then((response) => {
            console.log("response", response);
          })
          .catch((error) => {});
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const toggleModel = () => {
    setisModelOpen(true);
  };

  const getAllCustomers = (reset?: boolean) => {
    if (gym!._id) {
      if (reset) {
        service
          .callGet(
            `${API_HOSTNAME}/gym_customer/my_customers?gym_id=${gym!._id}`,
          )
          .then((response) => {
            // setCustomers(response.data.data);
          })
          .catch((error) => {});
      } else {
        axios
          .get(
            `${API_HOSTNAME}/gym_customer/my_customers?gym_id=${gym!._id}&keyword=${searchKeyword}`,
          )
          .then((response) => {
            setCustomers(response.data.data);
          })
          .catch((error) => {});
      }
    }
  };

  const addExercise = async () => {
    try {
      await service.callPost(`${API_HOSTNAME}/workouts/add-exercise`, {
        exercise_name: newExerciseName,
      });
      getAllExercises();
      setisModelOpen(false);
      Swal.fire("Success", "Workout Successfull Added", "success");
    } catch (error) {
      Swal.fire("Error", "", "error");
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) => option.exercise_name,
  });

  const columns: GridColDef<IGymCustomerMemberShipResponse>[] = [
    {
      field: "first_name",
      headerName: "First Name ",
      width: 150,
      renderCell: (params) => {
        return (
          <Link to={`/customer-detail/${params.row._id}`}>
            {params.row.first_name} {params.row.last_name}
          </Link>
        );
      },
    },
    { field: "last_name", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone_number", headerName: "Phone Number", width: 150 },
    {
      field: "membership_status",
      headerName: "Membership Status",
      width: 200,
      renderCell: (params) => {
        return params.row.membership_details ? (
          <Tooltip
            title={`Valid till ${moment(params.row.membership_details.plan_start_date).format("DD-MMM-YYYY")} to ${moment(params.row.membership_details.plan_end_date).format("DD-MMM-YYYY")}`}
          >
            <Chip
              label="Active"
              color="default"
              style={{ color: "green" }}
            ></Chip>
          </Tooltip>
        ) : (
          " - "
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <CustomButton
            fullWidth={true}
            type="success"
            onClick={() => {
              assignWorkoutToCustomer(params.row._id!);
            }}
            text="Assign"
          />
        );
      },
    },
  ];

  const assignWorkoutToCustomer = (customerId: string) => {
    service
      .callPost(`${API_HOSTNAME}/workouts/assign_workout_to_customer`, {
        customer_id: customerId,
        workout_plan_id: planDetails?._id,
        gym_id: gym?._id,
      })
      .then((response) => {});
  };

  return (
    <Container style={{ height: "100%", overflowY: "scroll" }}>
      {gym && gym._id ? (
        <>
          <CustomDialog
            isModalOpen={isModelOpen}
            onSaveClick={addExercise}
            onCancelClick={function (): void {
              setisModelOpen(false);
            }}
            headerText={"Add New Exercise"}
            fullWidth={false}
            maxWidth={"xs"}
          >
            <FormControl fullWidth>
              <TextField
                label={"Add Your Exercise "}
                onChange={(e) => {
                  setNewExerciseName(e.target.value);
                }}
              />
            </FormControl>
          </CustomDialog>

          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Plan and Workout details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                direction="column"
                className="card"
                padding={"10px 0px"}
                style={{ borderTop: "2px solid #4723d9" }}
              >
                <div className="card-header d-flex justify-content-between border-bottom pb-1">
                  <div className="">{title} </div>
                </div>

                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid
                    item
                    container
                    flex={0.5}
                    direction={"column"}
                    className={"mt-3 mb-3 mr-3"}
                    padding={"0 10px"}
                  >
                    <Grid item className={"mt-3 mb-3 mr-3"}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        style={{ width: "100%", padding: "5px 0" }}
                      >
                        {exerciseList.length && (
                          <Autocomplete
                            autoComplete={true}
                            disablePortal
                            filterOptions={filterOptions}
                            onChange={(
                              event: any,
                              newValue: IMasterExercises | null,
                            ) => {
                              newValue &&
                                setSingleWorkoutSet({
                                  ...singleWorkoutSet,
                                  exercise_id: newValue._id,
                                  exercise_name: newValue.exercise_name,
                                });
                            }}
                            options={exerciseList}
                            getOptionLabel={(option) => option.exercise_name}
                            sx={{ width: "100%" }}
                            includeInputInList
                            renderOption={(props, option) => (
                              <li {...props}>{option.exercise_name}</li>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Exercise" />
                            )}
                          />
                        )}
                      </FormControl>
                      <Button variant="text" onClick={toggleModel}>
                        Add Exercise
                      </Button>
                    </Grid>

                    <Grid item className={"mb-3 mr-3"}>
                      <FormControl fullWidth style={{ width: "100%" }}>
                        <InputLabel>Day</InputLabel>

                        <Select
                          value={singleWorkoutSet?.day}
                          label="Day"
                          onChange={(event) => {
                            setSingleWorkoutSet({
                              ...singleWorkoutSet,
                              day: event.target.value.toString(),
                            });
                          }}
                        >
                          <MenuItem value={"monday"}>Monday</MenuItem>
                          <MenuItem value={"tuesday"}>Tuesday</MenuItem>
                          <MenuItem value={"wednesday"}>Wednesday</MenuItem>
                          <MenuItem value={"thrusday"}>Thrusday</MenuItem>
                          <MenuItem value={"friday"}>Friday</MenuItem>
                          <MenuItem value={"saturday"}>Saturday</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item className={"mb-3 mr-3"}>
                      <FormControl fullWidth style={{ width: "100%" }}>
                        <InputLabel>Workout Category</InputLabel>
                        <Select
                          value={singleWorkoutSet?.workout_category}
                          label="Category"
                          onChange={(event) => {
                            setSingleWorkoutSet({
                              ...singleWorkoutSet,
                              workout_category: event.target.value.toString(),
                            });
                          }}
                        >
                          {exerciseCategory.map((category) => {
                            return (
                              <MenuItem value={category}>{category}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    {singleWorkoutSet.workout_category.toLowerCase() ===
                      "cardio" && (
                      <Grid item className={"mb-3 mr-3"}>
                        <FormControl fullWidth style={{ width: "100%" }}>
                          <InputLabel>Duration</InputLabel>

                          <Select
                            value={singleWorkoutSet?.duration_in_minutes}
                            label="duration"
                            onChange={(event) => {
                              setSingleWorkoutSet({
                                ...singleWorkoutSet,
                                duration_in_minutes: parseInt(
                                  event.target.value.toString(),
                                ),
                              });
                            }}
                          >
                            <MenuItem value={"5"}>5 Mintues</MenuItem>
                            <MenuItem value={"10"}>10 Minutes</MenuItem>
                            <MenuItem value={"15"}>15 Minutes</MenuItem>
                            <MenuItem value={"20"}>20 Minutes</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    <Grid
                      item
                      container
                      className={"mb-3 mr-3"}
                      direction={"row"}
                    >
                      <Grid item flex={0.5} padding={"0 5px"}>
                        <FormControl fullWidth style={{ width: "100%" }}>
                          <InputLabel>Sets</InputLabel>

                          <Select
                            value={singleWorkoutSet?.sets}
                            label="Sets"
                            onChange={(event) => {
                              setSingleWorkoutSet({
                                ...singleWorkoutSet,
                                sets: parseInt(event.target.value.toString()),
                              });
                            }}
                          >
                            <MenuItem value={"1"}>1</MenuItem>
                            <MenuItem value={"2"}>2</MenuItem>
                            <MenuItem value={"3"}>3</MenuItem>
                            <MenuItem value={"4"}>4</MenuItem>
                            <MenuItem value={"5"}>5</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item flex={0.5} padding={"0 5px"}>
                        <FormControl fullWidth style={{ width: "100%" }}>
                          <InputLabel>Reps</InputLabel>

                          <Select
                            value={singleWorkoutSet?.reps}
                            label="Reps"
                            onChange={(event) => {
                              setSingleWorkoutSet({
                                ...singleWorkoutSet,
                                reps: parseInt(event.target.value.toString()),
                              });
                            }}
                          >
                            <MenuItem value={"8"}>8</MenuItem>
                            <MenuItem value={"10"}>10</MenuItem>
                            <MenuItem value={"12"}>12</MenuItem>
                            <MenuItem value={"15"}>15</MenuItem>
                            <MenuItem value={"18"}>18</MenuItem>
                            <MenuItem value={"20"}>20</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <CustomButton
                        type="success"
                        text="Assign"
                        onClick={addExerciseToWorkOut}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  {/* Plan Details */}

                  <Grid
                    item
                    container
                    flex={0.5}
                    direction={"column"}
                    padding={"0 5px"}
                    style={{
                      height: 404,
                      overflowY: "scroll",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Grid item className="mt-3 mb-3 mr-3">
                      <h3>Plan</h3>
                    </Grid>
                    <Grid container direction={"column"}>
                      <Grid item className="mt-3 mb-3 mr-3">
                        <h3>
                          <b>Monday</b>
                        </h3>
                        <ul className="ml-5">
                          {getWorkoutsForday("monday").length
                            ? getWorkoutsForday("monday").map((set) => {
                                return (
                                  <li style={{ listStyleType: "number" }}>
                                    {set.exercise_name}{" "}
                                    {set.workout_category === "CARDIO"
                                      ? ` - ${set.duration_in_minutes} minutes`
                                      : `${set.reps.toString()} reps x ${set.sets.toString()} sets`}
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        removeExercise(set._id!, "monday");
                                      }}
                                    >
                                      remove
                                    </Button>
                                  </li>
                                );
                              })
                            : "Rest"}
                        </ul>
                      </Grid>
                      <Grid item className="mt-3 mb-3 mr-3">
                        <h3>
                          <b>Tuesday</b>
                        </h3>
                        <ul className="ml-5">
                          {getWorkoutsForday("tuesday").length
                            ? getWorkoutsForday("tuesday").map((set) => {
                                return (
                                  <li style={{ listStyleType: "number" }}>
                                    {set.exercise_name}{" "}
                                    {set.workout_category === "CARDIO"
                                      ? ` - ${set.duration_in_minutes} minutes`
                                      : `${set.reps.toString()} reps x ${set.sets.toString()} sets`}
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        removeExercise(
                                          set.exercise_id,
                                          "tuesday",
                                        );
                                      }}
                                    >
                                      remove
                                    </Button>{" "}
                                  </li>
                                );
                              })
                            : "Rest"}
                        </ul>
                      </Grid>

                      <Grid item className="mt-3 mb-3 mr-3">
                        <h3>
                          <b>Wednesday</b>
                        </h3>
                        <ul className="ml-5">
                          {getWorkoutsForday("Wednesday").length
                            ? getWorkoutsForday("Wednesday").map((set) => {
                                return (
                                  <li style={{ listStyleType: "number" }}>
                                    {set.exercise_name}{" "}
                                    {set.workout_category === "CARDIO"
                                      ? ` - ${set.duration_in_minutes} minutes`
                                      : `${set.reps.toString()} reps x ${set.sets.toString()} sets`}
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        removeExercise(
                                          set.exercise_id,
                                          "wednesday",
                                        );
                                      }}
                                    >
                                      remove
                                    </Button>
                                  </li>
                                );
                              })
                            : "Rest"}
                        </ul>
                      </Grid>

                      <Grid item className="mt-3 mb-3 mr-3">
                        <h3>
                          <b>Thrusday</b>
                        </h3>
                        <ul className="ml-5">
                          {getWorkoutsForday("Thrusday").length
                            ? getWorkoutsForday("Thrusday").map((set) => {
                                return (
                                  <li style={{ listStyleType: "number" }}>
                                    {set.exercise_name}{" "}
                                    {set.workout_category === "CARDIO"
                                      ? ` - ${set.duration_in_minutes} minutes`
                                      : `${set.reps.toString()} reps x ${set.sets.toString()} sets`}
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        removeExercise(
                                          set.exercise_id,
                                          "Thrusday",
                                        );
                                      }}
                                    >
                                      remove
                                    </Button>
                                  </li>
                                );
                              })
                            : "Rest"}
                        </ul>
                      </Grid>

                      <Grid item className="mt-3 mb-3 mr-3">
                        <h3>
                          <b>Friday</b>
                        </h3>
                        <ul className="ml-5">
                          {getWorkoutsForday("Friday").length
                            ? getWorkoutsForday("Friday").map((set) => {
                                return (
                                  <li style={{ listStyleType: "number" }}>
                                    {set.exercise_name}{" "}
                                    {set.workout_category === "CARDIO"
                                      ? ` - ${set.duration_in_minutes} minutes`
                                      : `${set.reps.toString()} reps x ${set.sets.toString()} sets`}
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        removeExercise(
                                          set.exercise_id,
                                          "Friday",
                                        );
                                      }}
                                    >
                                      remove
                                    </Button>
                                  </li>
                                );
                              })
                            : "Rest"}
                        </ul>
                      </Grid>
                      <Grid item className="mt-3 mb-3 mr-3">
                        <h3>
                          <b>Saturday</b>
                        </h3>
                        <ul className="ml-5">
                          {getWorkoutsForday("Saturday").length
                            ? getWorkoutsForday("Saturday").map((set) => {
                                return (
                                  <li style={{ listStyleType: "number" }}>
                                    {set.exercise_name}{" "}
                                    {set.workout_category === "CARDIO"
                                      ? ` - ${set.duration_in_minutes} minutes`
                                      : `${set.reps.toString()} reps x ${set.sets.toString()} sets`}
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        removeExercise(
                                          set.exercise_id,
                                          "Saturday",
                                        );
                                      }}
                                    >
                                      remove
                                    </Button>
                                  </li>
                                );
                              })
                            : "Rest"}
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item padding={"0 10px"}>
                  <CustomButton
                    fullWidth={true}
                    onClick={savePlan}
                    type="success"
                    text="Save Workout Plan"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>Assign to customers</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                style={{ overflowY: "scroll" }}
                direction={"column"}
              >
                <Grid
                  item
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item flex={0.88}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        placeholder="Search Customer"
                        inputProps={{ style: { height: 10 } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item flex={0.1}>
                    <CustomButton
                      type={"success"}
                      onClick={function (): void {
                        throw new Error("Function not implemented.");
                      }}
                      text={"Search"}
                      fullWidth={false}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  className="mt-3 mb-3"
                  direction="column"
                  paddingTop={5}
                  overflow={"scroll"}
                >
                  <DataGrid
                    rows={customers}
                    columns={columns}
                    paginationMode="client"
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    getRowId={(row) => row._id!}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <Alert severity="error">
          Please add your gym details to add workouts{" "}
          <Link className="dropdown-item" to="/gym-info">
            Add Gym Details
          </Link>
        </Alert>
      )}
    </Container>
  );
};
