import {
  Breakpoint,
  Button,
  Dialog,
  Fade,
  Grid,
  Typography,
} from "@mui/material";

export interface ICustomDialog extends React.PropsWithChildren {
  isModalOpen: boolean;
  onSaveClick: () => void;
  onCancelClick: () => void;
  headerText: string;
  fullWidth: boolean;
  maxWidth: Breakpoint;
}

export const CustomDialog = (props: ICustomDialog) => {
  return (
    <Dialog fullWidth open={props.isModalOpen} maxWidth={props.maxWidth}>
      <Fade in={props.isModalOpen}>
        <Grid container style={{ padding: 10 }} direction={"row"}>
          <Grid
            container
            item
            justifyContent={"space-between"}
            style={{ padding: 2 }}
            marginBottom={2}
          >
            <Grid item>
              <Typography variant="h6" component="h5">
                {props.headerText}
              </Typography>
            </Grid>
          </Grid>

          {props.children}

          {/*  footer */}
          <Grid container item justifyContent={"space-between"} marginTop={10}>
            <Grid item flex={0.49}>
              <Button
                variant="contained"
                color={"error"}
                fullWidth
                onClick={props.onCancelClick}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item flex={0.49}>
              <Button
                variant="contained"
                color={"primary"}
                fullWidth
                onClick={props.onSaveClick}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </Dialog>
  );
};
